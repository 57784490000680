<div class="dialog-delete-tune">
    <h1 mat-dialog-title>Delete {{type}}</h1>
    
      <div class="dialog-delete-tune__warning">
        Are you sure you want to delete this {{type}}? You will not be able to recover it.
      </div>
    
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="danger" (click)="deleteTune()" cdkFocusInitial [disabled]="loading"
          class="dialog-delete-tune__delete-button">
          <span *ngIf="!loading">Delete {{type}}</span>
          <mat-spinner class="dialog-delete-tune__delete-button__spinner" *ngIf="loading" diameter="25"></mat-spinner>
        </button>
      </div>
</div>
