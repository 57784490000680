<div class="navigation-profile">
  <div class="navigation-profile__image" [matMenuTriggerFor]="profileMenu">
    <img *ngIf="profileImage" [src]="profileImage" alt="Profile image" />
  </div>

  <mat-menu #profileMenu="matMenu" class="navigation-profile__dropdown">
    <button mat-menu-item aria-label="Go to my profile" routerLink="/profile/{{user?.username}}">
        <mat-icon>person</mat-icon>
        <span>My profile</span>
    </button>
    <!-- <button mat-menu-item (click)="goToShop()" aria-label="Go to the Tunr shop">
      <mat-icon>shopping_cart</mat-icon>
      <span>Shop</span>
    </button> -->
    <button mat-menu-item routerLink="/settings" aria-label="Go to the settings page">
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item routerLink="/about" aria-label="Go to the about page">
      <mat-icon>info</mat-icon>
      <span>About</span>
    </button>
    <button mat-menu-item (click)="logout()" aria-label="Log out">
      <mat-icon>logout</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</div>
