import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Comment } from 'src/app/features/shared/interfaces/comment';

@Injectable({
  providedIn: 'root'
})
export class TransferDataService {

  private tunePostedSubject = new Subject<any>();
  private addCommentSubject = new Subject<any>();
  private deleteCommentSubject = new Subject<any>();
  private closeDialogSubject = new Subject<any>();

  tunePosted$ = this.tunePostedSubject.asObservable();
  addComment$ = this.addCommentSubject.asObservable();
  deleteComment$ = this.deleteCommentSubject.asObservable();
  closeDialog$ = this.closeDialogSubject.asObservable();

  constructor() { }

  tunePosted = () => {
    this.tunePostedSubject.next();
  }

  addComment = (comment: Comment) => {
    comment.date = Date.now();
    this.addCommentSubject.next(comment);
  }

  deleteComment = (keys: any) => {
    this.deleteCommentSubject.next(keys);
  }

  closeDialog = () => {
    this.closeDialogSubject.next();
  }
}
