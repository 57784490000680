<div class="comment">
    <div class="comment__image" routerLink="/profile/{{comment?.username}}">
        <img [src]="comment.image" alt="Comment poster image" />
    </div>
    <div class="comment__copy">
        <div class="comment__copy__header">
          <div class="comment__copy__header__name" routerLink="/profile/{{comment?.username}}">
            {{comment?.username}}
          </div> 
          <div class="comment__copy__header__date">
            &middot; {{comment?.date | timeago: false}}
          </div> 
          <div class="comment__copy__header__reply" *ngIf="!hideReply">
            <button mat-button class="comment__copy__header__reply__button" (click)="openReply()">
              <mat-icon inline="true" class="comment__copy__header__reply__button__icon">reply</mat-icon> Reply
            </button>
          </div>

          <div class="comment__copy__header__options" *ngIf="comment?.author !== uid">
            <button mat-icon-button class="comment__copy__header__options__button" [matMenuTriggerFor]="commentOptionsMenu" aria-label="Open comment options">
              <mat-icon inline="true">more_horiz</mat-icon>
            </button>
            <mat-menu #commentOptionsMenu="matMenu">
              <button mat-menu-item (click)="reportComment()" aria-label="Report comment">
                <mat-icon>flag</mat-icon>
                <span>Report comment</span>
              </button>
            </mat-menu>
          </div>

          <div class="comment__copy__header__options" *ngIf="comment?.author === uid">
            <button mat-icon-button class="comment__copy__header__options__button" [matMenuTriggerFor]="commentOptionsMenu" aria-label="Open comment options">
              <mat-icon inline="true">more_horiz</mat-icon>
            </button>
            <mat-menu #commentOptionsMenu="matMenu">
              <button mat-menu-item (click)="deleteComment()" aria-label="Delete comment">
                <mat-icon>delete</mat-icon>
                <span>Delete comment</span>
              </button>
            </mat-menu>
          </div>

        </div>
        <div class="comment__copy__content">
          <span class="comment__copy__content__username" *ngIf="comment?.parentUsername" routerLink="/profile/{{comment?.parentUsername}}">
            @{{comment?.parentUsername}} 
          </span>
          {{comment?.message}}
        </div>
    </div>
  </div>