<div class="notification" (click)="onClickNotification()">

  <div class="notification__image-wrapper">
    <div class="notification__image-wrapper__image">
      <img [src]="notification?.image" />
    </div>
  </div>

  <div class="notification__text">

    <div *ngIf="notification?.type === 'newNotification'">
      <span><b>{{notification?.latestUser?.username}}</b> </span>

      <span *ngIf="(notification?.users?.length || 0) === 2"> and 1 other </span>
      <span *ngIf="(notification?.users?.length || 0) > 2"> and {{(notification?.users?.length || 0) - 1}} others
      </span>

      <span *ngIf="notification?.liked"> liked your tune </span>
      <span *ngIf="notification?.comment"> commented on your tune </span>
      <span *ngIf="notification?.commentedOnTune"> commented on a tune you commented on </span>
      <span *ngIf="notification?.reply"> replied to your comment on </span>
      <span *ngIf="notification?.repost"> reposted your tune </span>

      <span><b>{{notification?.uploadTitle}}</b> </span>
      <br />
      <small>{{notification?.date | timeago:false }} </small>
    </div>

    <span *ngIf="notification?.type === 'newTuner'">
      <span><b>{{notification?.username}}</b> is now tuned into you</span>
      <br />
      <small>{{notification?.date | timeago:false }} </small>
    </span>

    <div *ngIf="notification?.type === 'activity'">
      <div *ngIf="notification?.liked || notification?.comment">
        <span><b>{{notification?.myUsername}}</b> </span>
        <span *ngIf="(notification?.liked)"> liked </span>
        <span *ngIf="(notification?.comment)"> commented on </span>
        <span><b>{{notification?.otherUsername}}'s</b> tune</span>

        <br />
        <small>{{notification?.date | timeago:false }}</small>
      </div>

      <div *ngIf="notification?.tunedin">
        <span><b>{{notification?.myUsername}}</b> is now tuned in into <b>{{notification?.otherUsername}}</b></span>

        <br />
        <small>{{notification?.date | timeago:false }}</small>
      </div>
    </div>

  </div>

  <div class="notification__tune-in" *ngIf="notification?.type === 'newTuner'">
    <div class="notification__tune-in__button">
      <app-tune-in-button (click)="stopRouterLink($event)" [username]="notification?.username"
        [userUid]="notification?.author"></app-tune-in-button>
    </div>
  </div>

  <div class="notification__did-not-see" *ngIf="notification?.didnotsee">
    <mat-icon color="primary">fiber_manual_record</mat-icon>
  </div>

</div>
