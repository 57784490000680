import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { User } from 'src/app/features/shared/interfaces/user';
import firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TuneInService {

  uid!: string | null;
  user!: User | null;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService
  ) {
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })
    this.authService.user$.subscribe(user => {
      this.user = user ? user : null;
    })
  }

  checkIfTunedIn = async (userUid: string): Promise<boolean> => {
    return await this.db.object(`/following/${this.uid}/${userUid}`).valueChanges().pipe(take(1)).toPromise().then(value =>
      value ? true : false
    )
  }

  getUserData = async (userUid: string): Promise<User> => {
    return await this.db.object(`/users/${userUid}`).valueChanges().pipe(take(1)).toPromise().then((user: any) =>
      user ? user : null
    )
  }

  tuneIn = async (userUid: string) => {
    let userIsTunedIn: boolean;

    if (!this.user) return;

    // Check if the user is already tuned in
    userIsTunedIn = await this.checkIfTunedIn(userUid);

    if (!userIsTunedIn) {
      const userData: User = await this.getUserData(userUid);

      // Adds the uploads of the other person to your feed
      this.db.list(`/uploads/${userUid}`).valueChanges().pipe(take(1)).subscribe((uploads: any) => {
        uploads.forEach((upload: any) => {
          this.db.object(`/feeds/${this.uid}/${upload.key}`).set({
            key: upload.key
          })
        })
      })

      // Adds the other user to your followingUsernames
      this.db.object(`/followingUsernames/${this.uid}/${userData.username}`).set(true);

      // Adds to other user to your following list
      this.db.object(`/following/${this.uid}/${userUid}`).set({
        regUser: userData.regUser,
        username: userData.username,
        firstname: userData.firstname,
        lastname: userData.lastname,
        fullname: `${userData.firstname} ${userData.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP
      });

      // Adds your data to the other users followers
      this.db.object(`/followers/${userUid}/${this.uid}`).set({
        regUser: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP
      });

      // Update the amount of people you follow
      this.db.object(`/followingCount/${this.uid}/followingCount`).query.ref.transaction(count => count ? ++count : 1);

      // Update the amount of followers the other person has
      this.db.object(`/followersCount/${userUid}/followersCount`).query.ref.transaction(count => count ? ++count : 1);

      // Pushes a notification to the other user
      this.db.object(`/notificationsTune/${userUid}/${this.uid}`).set({
        author: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP,
        didnotsee: true
      })

      // Updates the amount of new notifications to the other user
      this.db.object(`/notificationsTuneCount/${userUid}/${this.uid}`).set(true);

      // Pushes the activityLog to each follower, except for the other user himself
      this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
        followers.forEach((follower: any) => {

          // Don't push it to the log of the other person
          if (follower.regUser !== userUid) {
            
            if (!this.user) return;

            this.db.object(`/activityLog/${follower.regUser}/${this.uid}${userUid}tunedin`).set({
              date: firebase.database.ServerValue.TIMESTAMP,
              tunedin: true,
              author: this.uid,
              otherPerson: userUid,
              otherUsername: userData.username,
              otherFirstname: userData.firstname,
              otherLastname: userData.lastname,
              myUsername: this.user.username,
              myFirstname: this.user.firstname,
              myLastname: this.user.lastname,
              myFullname: `${this.user.firstname} ${this.user.lastname}`
            });
          };
        });
      });
    };
  };

  tuneOut = async (userUid: string) => {
    let userIsTunedIn: boolean;

    // Check if the user is already tuned in
    userIsTunedIn = await this.checkIfTunedIn(userUid);

    if (userIsTunedIn) {
      const userData: User = await this.getUserData(userUid);
  
      // Removes the uploads of the other person from your feed
      this.db.list(`/uploads/${userUid}`).valueChanges().pipe(take(1)).subscribe((uploads: any) => {
        uploads.forEach((upload: any) => {
          this.db.object(`/feeds/${this.uid}/${upload.key}`).set(null);
        })
      })

      // Removes the other user from your followingUsernames
      this.db.object(`/followingUsernames/${this.uid}/${userData.username}`).set(null);

      // Removes the other user from your following list
      this.db.object(`/following/${this.uid}/${userUid}`).set(null);

      // Removes your data from the other users followers
      this.db.object(`/followers/${userUid}/${this.uid}`).set(null);

      // Update the amount of people you follow
      this.db.object(`/followingCount/${this.uid}/followingCount`).query.ref.transaction((count: any) => count ? --count : 0);

      // Update the amount of followers the other person has
      this.db.object(`/followersCount/${userUid}/followersCount`).query.ref.transaction((count: any) => count ? --count : 0);

      // Removes a notification from the other user
      this.db.object(`/notificationsTune/${userUid}/${this.uid}`).set(null);

      // Updates the amount of new notifications to the other user
      this.db.object(`/notificationsTuneCount/${userUid}/${this.uid}`).set(null);

      // Removes the activityLog from each follower
      this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe((followers: any) => {
        followers.forEach((follower: any) => {

          // Don't push it to the log of the other person
          if (follower.regUser !== userUid) {
            this.db.object(`/activityLog/${follower.regUser}/${this.uid}${userUid}tunedin`).set(null);
          };

        });
      });
    };
  };
}
