import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-list-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @Output('onClickUser') onClickUser: EventEmitter<any> = new EventEmitter();

  @Input() user: any;
  
  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  stopRouterLink = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }

  goTo = () => {
    this.router.navigate([`/profile/${this.user.username}`]);
    this.onClickUser.emit();
  }
}
