import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Tune } from 'src/app/features/shared/interfaces/tune';

@Injectable({
  providedIn: 'root'
})
export class YoutubeVideoService {
  uid!: string | null;
  activeTune!: Tune;
  previousTune!: Tune | null;
  nextTune!: Tune | null;
  isMediaplayerVisible: boolean = false;
  mediaplayerSizeObs$: Observable<any>;

  private activeTuneSubject = new Subject<Tune>();
  private playByIdSubject = new Subject<Tune>();
  private playPauseSubject = new Subject<null>();
  private youtubeStateSubject = new Subject<number>();
  private expandMediaplayerSubject = new Subject<any>();
  private minimizeMediaplayerSubject = new Subject<any>();
  private previousTuneSubject = new Subject<Tune>();
  private nextTuneSubject = new Subject<Tune>();
  private tuneEndedSubject = new Subject<Tune>();
  private noPreviousTuneSubject = new Subject<any>();
  private noNextTuneSubject = new Subject<any>();
  private setSiblingsSubject = new Subject<any>();
  private mediaplayerVisibleSubject = new Subject<any>();
  private seekToSubject = new Subject<any>();
  private youtubeRepeatSubject = new Subject<any>();
  private youtubeVolumeSubject = new Subject<any>();
  private youtubeVolumeOnSubject = new Subject<any>();
  private youtubeVolumeOffSubject = new Subject<any>();
  private checkMediaplayerSizeSubject = new Subject<any>();
  private closeMediaplayerSubject = new Subject<any>();

  activeTune$ = this.activeTuneSubject.asObservable();
  playById$ = this.playByIdSubject.asObservable();
  playPause$ = this.playPauseSubject.asObservable();
  youtubeState$ = this.youtubeStateSubject.asObservable();
  minimizeMediaplayer$ = this.minimizeMediaplayerSubject.asObservable();
  expandMediaplayer$ = this.expandMediaplayerSubject.asObservable();
  previousTune$ = this.previousTuneSubject.asObservable();
  nextTune$ = this.nextTuneSubject.asObservable();
  tuneEnded$ = this.tuneEndedSubject.asObservable();
  noPreviousTune$ = this.noPreviousTuneSubject.asObservable();
  noNextTune$ = this.noNextTuneSubject.asObservable();
  setSiblings$ = this.setSiblingsSubject.asObservable();
  mediaplayerVisible$ = this.mediaplayerVisibleSubject.asObservable();
  seekToSubject$ = this.seekToSubject.asObservable();
  youtubeRepeatSubject$ = this.youtubeRepeatSubject.asObservable();
  youtubeVolumeSubject$ = this.youtubeVolumeSubject.asObservable();
  youtubeVolumeOnSubject$ = this.youtubeVolumeOnSubject.asObservable();
  youtubeVolumeOffSubject$ = this.youtubeVolumeOffSubject.asObservable();
  checkMediaplayerSizeObs$ = this.checkMediaplayerSizeSubject.asObservable();
  closeMediaplayerObs$ = this.closeMediaplayerSubject.asObservable();

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService
  ) {
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })

    this.mediaplayerSizeObs$ = this.checkMediaplayerSizeSubject.asObservable();
  }

  // If a tune is given, set that one as the active tune. For example, this can be the case when updating the progress manually
  updateActiveTune = (tune?: Tune) => {
    if (tune) {
      this.activeTune = { ...tune };
      this.activeTuneSubject.next(tune);
    } else {
      this.activeTuneSubject.next(this.activeTune);
    }
  }

  playTune = (tune: Tune, type: string = 'snippet', listKey?: string, restart?: boolean) => {
    const newTune = { ...tune, type, listKey };
    this.setActiveTune(newTune, restart);
  }

  setActiveTune = (tune: Tune, restart?: boolean) => {
    // Snippet or full / different tune / different uploadUrl / tune ended (so repeat)
    if (this.activeTune?.type !== tune.type ||
      this.activeTune?.key !== tune.key ||
      this.activeTune?.uploadUrl !== tune.uploadUrl ||
      this.activeTune?.youtubeState === 0 ||
      restart) {

      // Load a new tune
      tune.progressSnippet = 0;
      tune.progress = 0;
      this.activeTune = { ...tune };
      this.activeTune.isPreview = tune.isPreview ? true : false;

      this.updateActiveTune();
      this.playByIdSubject.next(this.activeTune);
      this.setSiblingsSubject.next(this.activeTune);
    } else {
      // Play or pause the current tune
      this.playPauseSubject.next();
    }
  }

  changeYoutubeState = (youtubeState: number) => {
    this.activeTune.youtubeState = youtubeState;
    this.updateActiveTune();
  }

  updateProgress = (currentTime: string, duration: string, progress: number, progressSnippet?: number) => {
    this.activeTune.progressSnippet = progressSnippet ? progressSnippet : 0;
    this.activeTune.progress = progress;
    this.activeTune.currentTime = currentTime;
    this.activeTune.duration = duration;
    this.updateActiveTune();
  }

  minimizeMediaplayer = () => {
    this.minimizeMediaplayerSubject.next();
  }

  setPreviousTune = (tune: Tune | null) => {
    this.previousTune = tune ? { ...tune } : null;
    if (this.previousTune) {
      this.noPreviousTuneSubject.next(true);
    } else {
      this.noPreviousTuneSubject.next(false);
    }
  }

  setNextTune = (tune: Tune | null) => {
    this.nextTune = tune ? { ...tune } : null;
    if (this.nextTune) {
      this.noNextTuneSubject.next(true);
    } else {
      this.noNextTuneSubject.next(false);
    }
  }

  tuneEnded = async () => {
    const hasAutoplay = await this.db.object(`/settings/${this.uid}`).valueChanges().pipe(take(1)).toPromise()
    .then((settings:any) => (settings && settings.autoplayWeb === false) ? false : true);

    if (hasAutoplay && this.nextTune) {
      this.playTune(this.nextTune, this.activeTune?.type, this.activeTune?.listKey)
    };
  }

  playPrevious = () => {
    if (this.previousTune) {
      this.playTune(this.previousTune, this.activeTune?.type, this.activeTune?.listKey);
    }
  }

  playNext = () => {
    if (this.nextTune) {
      this.playTune(this.nextTune, this.activeTune?.type, this.activeTune?.listKey);
    }
  }

  seekTo = (value: any) => {
    this.seekToSubject.next(value);
  }

  youtubeRepeat = (values: any) => {
    this.youtubeRepeatSubject.next(values);
  }

  youtubeVolumeSlider(value: number) {
    this.youtubeVolumeSubject.next(value);
  }

  youtubeVolumeOn() {
    this.youtubeVolumeOnSubject.next();
  }

  youtubeVolumeOff() {
    this.youtubeVolumeOffSubject.next();
  }

  mediaplayerVisible = (visible: boolean) => {
    this.mediaplayerVisibleSubject.next(visible);
    this.isMediaplayerVisible = visible;
  }

  updateMediaplayerSize = (open: boolean) => {
    this.checkMediaplayerSizeSubject.next(open);
  }

  expandMediaplayer = () => {
    this.expandMediaplayerSubject.next();
  }

  closeMediaplayer() {
    this.closeMediaplayerSubject.next();
  }

  getMediaplayerVisible = () => {
    return this.isMediaplayerVisible;
  }
}
