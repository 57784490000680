import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comment } from '../../interfaces/comment';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  uid!: string | null;
  tune!: Tune;
  comment!: Comment;
  tuneKey!: string;
  reportTypes: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (data.tune) {
      this.tune = {...data.tune};
    } else if (data.comment && data.tuneKey) {
      this.comment = data.comment;
      this.tuneKey = data.tuneKey;
    }

    this.reportTypes = this.fb.group({
      spam: false,
      inappropriate: false,
      notmusic: false
    });
  }

  ngOnInit(): void {};

  onNoClick(): void {
    this.dialogRef.close();
  }
}
