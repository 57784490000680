import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { User } from 'src/app/features/shared/interfaces/user';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-navigation-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  private subs = new SubSink();

  uid!: string | null;
  user!: User | null;
  profileImage!: string | null;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase
  ) { 
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;

      if (this.uid) {
        this.setProfileImage();
      } 
    }));

    this.subs.add(this.authService.user$.subscribe(user => {
      this.user = user ? user : null;
    }));
  }

  ngOnInit(): void {
  }

  setProfileImage = () => {
    this.subs.add(this.db.object(`/images/${this.uid}/image`).valueChanges().subscribe((image: any) => {
      this.profileImage = image;
    }));
  }

  goToShop = () => {
    (window as any).open(`https://shop.tunrmusic.com`, "_blank");
  }

  logout = () => {
    return this.authService.logout();
  }
}
