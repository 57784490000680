import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TuneActionsService } from 'src/app/core/services/tune-actions/tune-actions.service';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-repost',
  templateUrl: './repost.component.html',
  styleUrls: ['./repost.component.scss']
})
export class RepostComponent implements OnInit {
  tune!: Tune;
  loading: boolean = false;
  repostDescription!: string;

  constructor(
    private tuneActionsService: TuneActionsService,
    private dialogRef: MatDialogRef<RepostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tune = {...data.tune};

    if (this.tune.repostDescription) {
      this.repostDescription = this.tune.repostDescription;
    }
  }

  ngOnInit(): void {
  }

  repost = () => {
    this.loading = true;
    this.tuneActionsService.repost(this.tune, this.repostDescription);

    setTimeout(() => {
      this.dialogRef.close();
    }, 500);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
