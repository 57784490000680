import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-external',
  templateUrl: './search-external.component.html',
  styleUrls: ['./search-external.component.scss']
})
export class SearchExternalComponent implements OnInit {

  uploadTitle!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    if (data && data.uploadTitle) this.uploadTitle = data.uploadTitle;
  }

  ngOnInit(): void {
  }

  searchOnSpotify() {
    const baseUrl = "https://open.spotify.com/search/";
    const titleURI = encodeURIComponent(this.uploadTitle);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnDiscogs() {
    const baseUrl = "https://www.discogs.com/search/?q=";
    const titleURI = encodeURIComponent(this.uploadTitle);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnBandcamp() {
    const baseUrl = "https://bandcamp.com/search?q=";
    const titleURI = encodeURIComponent(this.uploadTitle);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnAppleMusic() {
    const baseUrl = "https://music.apple.com/us/search?term=";
    const titleURI = encodeURIComponent(this.uploadTitle);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }
}
