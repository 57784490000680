import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class InspirationalService {

  constructor(private db: AngularFireDatabase) { }

  getInspirationalArtists = (batch: number, lastNumber?: number) => {
    return this.db.list(`/blogsShort`, ref => {
      if (lastNumber) {
        return ref.orderByChild('number').limitToLast(batch).endAt(lastNumber)
      } else {
        return ref.orderByChild('number').limitToLast(batch)
      };
    });
  };
}
