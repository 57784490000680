import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditTuneService } from 'src/app/core/services/edit-tune/edit-tune.service';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-edit-buyinfo',
  templateUrl: './edit-buyinfo.component.html',
  styleUrls: ['./edit-buyinfo.component.scss']
})
export class EditBuyinfoComponent implements OnInit {
  tune!: Tune;
  editBuyInfoForm!: FormGroup;
  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EditBuyinfoComponent>,
    private editTuneService: EditTuneService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tune = {...data.tune};

  }

  ngOnInit(): void {
    this.editBuyInfoForm = this.formBuilder.group({
      buyNowLink: [this.tune.buyNowLink],
      selectedCurrency: [this.tune.selectedCurrency],
      buyAmount: [this.tune.buyAmount]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save = (editBuyInfoForm: FormGroup) => {
    if (!editBuyInfoForm.valid || !this.tune.key) return;

    this.loading = true;

    const buyNowLink = editBuyInfoForm.value.buyNowLink;
    const buyAmount = editBuyInfoForm.value.buyAmount;
    const selectedCurrency = editBuyInfoForm.value.selectedCurrency;

    this.editTuneService.editLink(buyNowLink, this.tune.key);
    this.editTuneService.editPrice(buyAmount, selectedCurrency, this.tune.key);

    if (buyNowLink === '') {
      this.editTuneService.editBuyComplete(false, this.tune.key)
    } else {
      this.editTuneService.editBuyComplete(true, this.tune.key)
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, 500);
  }
}
