<div class="dialog-auth-required">

  <div class="dialog-auth-required__header">
    Create a Tunr account
    <div class="dialog-auth-required__header__close">
      <button mat-icon-button aria-label="Close dialog" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="dialog-auth-required__content">
    <div class="dialog-auth-required__content__logo">
      <img class="registerFacebookImg" src="assets/images/logo_T_trans.png">
    </div>

    <div class="dialog-auth-required__content__text">
      You have to have a Tunr account to {{action}}
    </div>

    <div class="dialog-auth-required__content__login">
      <button mat-stroked-button color="primary" aria-label="Log in" (click)="goTo('login')"><b>Log in</b></button>
      <div class="dialog-auth-required__content__login__or">or</div>
      <button mat-raised-button color="primary" aria-label="Create account" (click)="goTo('register')">Create
        account</button>
    </div>

    <div class="dialog-auth-required__content__go-mobile visible-s">
        <div class="dialog-auth-required__content__go-mobile__text">Go mobile</div>
        <app-use-app></app-use-app>
    </div>
  </div>
</div>
