import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EditTuneService } from 'src/app/core/services/edit-tune/edit-tune.service';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-delete-tune',
  templateUrl: './delete-tune.component.html',
  styleUrls: ['./delete-tune.component.scss']
})
export class DeleteTuneComponent implements OnInit {
  uid!: string | null;
  tune!: Tune;
  loading: boolean = false;
  type: string = 'tune'; // 'tune' / 'repost' / null

  constructor(
    private dialogRef: MatDialogRef<DeleteTuneComponent>,
    private editTuneService: EditTuneService,
    private db: AngularFireDatabase,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

    this.uid = this.authService.getUid();
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })

    this.tune = {...data.tune};
    if (data.type) this.type = data.type;
  }

  ngOnInit(): void {};

  deleteTune = async () => {
    if (!this.tune.key) return;

    this.loading = true;

    if (this.tune.isRepost) {
      this.editTuneService.deleteRepostTune(this.tune);
    } else if (this.tune.reposted) {
      // Check what the original key is of your repost
      this.db.object(`/repostedTunes/${this.uid}/${this.tune.key}/originalKey`).valueChanges().pipe(take(1)).subscribe((originalKey: any) => {
        if (originalKey) {
          this.editTuneService.deleteRepostTune(this.tune, originalKey);
        }
      })
    } else {
      this.editTuneService.deleteTune(this.tune.key);
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, 500);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
