<div class="search-external">
    <div class="search-external__header">
        Search for this tune on various music platforms:
    </div>
    <div class="search-external__title">
        {{uploadTitle}}
    </div>

    <div class="search-external__button" matRipple (click)="searchOnSpotify()">
        <div class="search-external__button__logo">
            <i class="fab fa-spotify search-external__button__logo__spotify"></i>
        </div>
        <div class="search-external__button__text">
            Spotify
        </div>
        <div class="search-external__button__icon">   
            <mat-icon>open_in_new</mat-icon>
        </div>
    </div>

    <div class="search-external__button" matRipple (click)="searchOnDiscogs()">
        <div class="search-external__button__logo">
            <img class="search-external__button__logo__discogs" src="assets/images/discogs-icon.svg" />
        </div>
        <div class="search-external__button__text">
            Discogs
        </div>
        <div class="search-external__button__icon">
            <mat-icon>open_in_new</mat-icon>
        </div>
    </div>

    <div class="search-external__button" matRipple (click)="searchOnBandcamp()">
        <div class="search-external__button__logo">
            <i class="fab fa-bandcamp search-external__button__logo__bandcamp"></i>
        </div>
        <div class="search-external__button__text">
            Bandcamp
        </div>
        <div class="search-external__button__icon">
            <mat-icon>open_in_new</mat-icon>
        </div>
    </div>

    <div class="search-external__button" matRipple (click)="searchOnAppleMusic()">
        <div class="search-external__button__logo">
            <img class="search-external__button__logo__apple" src="assets/images/apple-music-icon-black.svg" />
        </div>
        <div class="search-external__button__text">
            Apple Music
        </div>
        <div class="search-external__button__icon">
            <mat-icon>open_in_new</mat-icon>
        </div>
    </div>

</div>
