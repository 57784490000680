<h2 mat-dialog-title>Email address already exists</h2>
<mat-dialog-content class="mat-typography">
    <div *ngIf="methods.length === 0">The email address is already in use</div>
    <div *ngIf="methods.length > 0">
        The email address is already in use on Tunr, when logged in with: 
        
        <br />

        <ul>
            <li *ngFor="let method of methods">
                {{method}}
            </li>
        </ul>

        <br />

        Please log in with your existing account

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>