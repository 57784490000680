<div class="dialog-edit-buyinfo">
  <h1 mat-dialog-title>Edit buy info</h1>

  <form class="dialog-edit-buyinfo__form" [formGroup]="editBuyInfoForm" >

    <mat-form-field appearance="outline">
      <mat-label>Link to buy the tune</mat-label>
      <input matInput type="text" spellcheck="false" formControlName="buyNowLink">
    </mat-form-field>

    <div class="dialog-edit-buyinfo__form__price">

        <div class="dialog-edit-buyinfo__form__price__currency">
            <mat-form-field appearance="outline">
                <mat-select formControlName="selectedCurrency">
                    <mat-option value="$">
                        $
                    </mat-option>
                    <mat-option value="€">
                        €
                    </mat-option>
                    <mat-option value="&pound;">
                        &pound;
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="dialog-edit-buyinfo__form__price__amount">
            <mat-form-field appearance="outline">
                <input matInput type="number" placeholder="e.g. 2.99" formControlName="buyAmount">
            </mat-form-field>
        </div>

    </div>

  </form>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="save(editBuyInfoForm)" cdkFocusInitial [disabled]="loading"
      class="dialog-edit-buyinfo__save-button">
      <span *ngIf="!loading">Save</span>
      <mat-spinner class="dialog-edit-buyinfo__save-button__spinner" *ngIf="loading" diameter="25"></mat-spinner>
    </button>
  </div>

</div>
