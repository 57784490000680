import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { SubSink } from 'subsink';
import { AuthService } from '../../services/auth/auth.service';
import { TuneActionsService } from '../../services/tune-actions/tune-actions.service';
import { YoutubeVideoService } from '../../services/youtube-video/youtube-video.service';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { SearchExternalComponent } from 'src/app/features/shared/dialogs/search-external/search-external.component';
import { MatDialog } from '@angular/material/dialog';
import { ShareComponent } from 'src/app/features/shared/dialogs/share/share.component';
import { Router } from '@angular/router';
import { AuthRequiredComponent } from 'src/app/features/shared/dialogs/auth-required/auth-required.component';

@Component({
  selector: 'app-mediaplayer',
  templateUrl: './mediaplayer.component.html',
  styleUrls: ['./styles/mediaplayer.component.scss', './styles/mediaplayer-small.component.scss'],
  animations: [
    trigger('visibilityMediaplayer', [
      state('shown', style({
        opacity: 1,
        display: 'flex'
      })),
      state('hidden', style({
        opacity: 0,
        display: 'none'
      })),
      transition('* => *', animate('0.3s'))
    ])
  ]
})
export class MediaplayerComponent implements OnInit {

  private subs = new SubSink();

  isServer: boolean = true;

  uid!: string | null;
  activeTune!: Tune;
  autoplayOn!: boolean;
  activeKey!: string | undefined;
  likeSubscription!: Subscription;
  saveSubscription!: Subscription;
  hasPreviousTune!: boolean;
  hasNextTune!: boolean;
  matSliderIsMoving: boolean = false;
  rememberYoutubeState!: number;
  progressbarValue: number = 0;
  showVolumeSlider: boolean = false;
  volumeValue: number = 100;
  volumeValueCache: number = 20;
  volumeOn: boolean = true;
  visibilityMediaplayer: string = 'hidden';

  // On small screens
  mediaplayerSmallOpen: boolean = false;

  constructor(
    private youtubeVideoService: YoutubeVideoService,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private ch: ChangeDetectorRef,
    private tuneActionsService: TuneActionsService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.subs.add(this.authService.uid$.subscribe(uid => {
      if (uid) {
        this.uid = uid;
      } else {
        this.uid = null;
      }

      this.checkAutoplay();
    }))

    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    this.subs.add(this.youtubeVideoService.activeTune$.subscribe((activeTune: Tune) => {
      if (this.visibilityMediaplayer !== 'shown') {
        this.visibilityMediaplayer = 'shown';
        if (!this.isServer) {
          document.querySelectorAll('.smartbanner').forEach((el:any) => {
            el.style.display = "none"
          })
        }
      }

      this.activeTune = { ...activeTune };
      this.ch.detectChanges();
      this.setSubscriptions(this.activeTune);
    }));

    this.subs.add(this.youtubeVideoService.noPreviousTune$.subscribe((hasPreviousTune: boolean) => {
      this.hasPreviousTune = hasPreviousTune;
    }))

    this.subs.add(this.youtubeVideoService.noNextTune$.subscribe((hasNextTune: boolean) => {
      this.hasNextTune = hasNextTune;
    }))

    this.subs.add(this.youtubeVideoService.minimizeMediaplayer$.subscribe(() => {
      this.minimizeMediaplayer();
    }))

    this.youtubeVideoService.expandMediaplayer$.subscribe(
      () => {
        this.expandMediaplayer();
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  checkAutoplay = () => {
    // Listen to autoplay changes
    if (this.uid) {
      this.subs.add(this.db.object(`/settings/${this.uid}`).valueChanges().subscribe((settings: any) => {
        this.autoplayOn = (settings && settings.autoplayWeb === false) ? false : true;
      }))
    }
  }

  goTo = (type: string, id?: string) => {
    this.minimizeMediaplayer();

    if (this.activeTune?.blog && type === 'user') {
      this.router.navigate([`/inspirational/${id}`])
      return;
    }

    if (type === 'user') {
      this.router.navigate([`/profile/${id}`])
    }

    if (type === 'tune') {
      this.router.navigate([`/tunes/${id}`])
    }
  }

  playPrevious = () => {
    this.youtubeVideoService.playPrevious();
  }

  playNext = () => {
    this.youtubeVideoService.playNext();
  }

  playTune = (type?: string) => {
    if (!this.isServer) {
      document.querySelectorAll('.smartbanner').forEach((el:any) => {
        el.style.display = "none"
      })
    }
    
    if (type) {
      this.youtubeVideoService.playTune(this.activeTune, type, this.activeTune?.listKey);
    } else {
      this.youtubeVideoService.playTune(this.activeTune, this.activeTune.type, this.activeTune?.listKey);
    }
  }

  likeTune = () => {
    this.tuneActionsService.likeTune(this.activeTune);
    this.activeTune.likesCount ? this.activeTune.likesCount++ : this.activeTune.likesCount = 1;
    this.youtubeVideoService.updateActiveTune(this.activeTune);
  }

  dislikeTune = () => {
    this.tuneActionsService.dislikeTune(this.activeTune);
    this.activeTune.likesCount ? this.activeTune.likesCount-- : this.activeTune.likesCount = 0;
    this.youtubeVideoService.updateActiveTune(this.activeTune);
  }

  openSearchExternalDialog = () => {
    if (!this.activeTune) return;

    this.dialog.open(SearchExternalComponent, {
      data: { uploadTitle: this.activeTune.uploadTitle }
    });
  }

  openAuthRequired = (type: string) => {
    this.dialog.open(AuthRequiredComponent, {
      data: { type },
      width: '90%',
      maxWidth: 600
    });
  }

  openShareDialog = () => {
    const url = `https://www.tunrmusic.com/tunes/${this.activeTune.key}`;

    this.dialog.open(ShareComponent, {
      data: { url: url }
    });
  }

  saveTune = () => {
    this.tuneActionsService.saveTune(this.activeTune);
  }

  unsaveTune = () => {
    if (this.activeTune.key) this.tuneActionsService.unsaveTune(this.activeTune.key);
  }

  showVolume() {
    this.showVolumeSlider = true;
  }

  hideVolume() {
    this.showVolumeSlider = false;
  }

  turnVolumeOn() {
    this.volumeValue = this.volumeValueCache;
    this.volumeOn = true;
    this.youtubeVideoService.youtubeVolumeOn();
  }

  turnVolumeOff() {
    this.volumeValueCache = this.volumeValue;
    this.volumeOn = false;
    this.volumeValue = 0;
    this.youtubeVideoService.youtubeVolumeOff();
  }

  // Changes the current time of the song if the progressbar is dragged or clicked on
  onInputChangeVolume(event: any) {
    this.volumeValue = event.value;
    this.youtubeVideoService.youtubeVolumeSlider(event.value)

    if (event.value == 0) {
      this.volumeOn = false;
    } else {
      this.volumeOn = true;
    }
  }

  onToggleAutoplay = (event: any) => {
    const checked = event.checked ? true : false;
    this.db.object(`/settings/${this.uid}/autoplayWeb`).set(checked);
  }

  setSubscriptions = (tune: Tune) => {
    const key = tune.key;

    if (key !== this.activeKey) {
      if (this.saveSubscription) this.saveSubscription.unsubscribe(); // Unsubscribe if there is a previous subscription
      if (this.likeSubscription) this.likeSubscription.unsubscribe(); // Unsubscribe if there is a previous subscription

      this.activeKey = key;

      this.saveSubscription = this.db.object(`/addedtowaitlist/${this.uid}/${key}`).valueChanges().subscribe((saved: any) => {
        if (saved) {
          this.activeTune.saved = true;
          this.youtubeVideoService.updateActiveTune(this.activeTune);
        } else {
          this.activeTune.saved = false;
          this.youtubeVideoService.updateActiveTune(this.activeTune);
        }
      })

      this.likeSubscription = this.db.object(`/liked/${this.uid}/${key}`).valueChanges().subscribe((liked: any) => {
        if (liked) {
          this.activeTune.liked = true;
          this.youtubeVideoService.updateActiveTune(this.activeTune);
        } else {
          this.activeTune.liked = false;
          this.youtubeVideoService.updateActiveTune(this.activeTune);
        }
      })
    }
  }

  // This function will get called everytime the slider is touched or released
  onChangeSlider = () => {
    this.youtubeVideoService.seekTo(this.progressbarValue);
  }

  // This function gets called every time the input changes
  onInputChangeProgress = (event: any) => {
    this.progressbarValue = event.value;
  }

  expandMediaplayer = () => {
    this.mediaplayerSmallOpen = true;
    this.youtubeVideoService.updateMediaplayerSize(this.mediaplayerSmallOpen);
  }

  closeMediaplayer = () => {
    this.youtubeVideoService.closeMediaplayer();

    // Delay, because otherwise it opens up again due to the change in state
    if (!this.isServer) {
      setTimeout(() => {
        this.visibilityMediaplayer = 'hidden';
      }, 100);
    }
  }

  minimizeMediaplayer = () => {
    this.mediaplayerSmallOpen = false;
    this.youtubeVideoService.updateMediaplayerSize(this.mediaplayerSmallOpen);
  }
}
