import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TransferDataService } from '../transfer-data/transfer-data.service';
import { User } from 'src/app/features/shared/interfaces/user';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Router } from '@angular/router';
import { ShareComponent } from 'src/app/features/shared/dialogs/share/share.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class TuneActionsService {

  private hideTuneSubject = new Subject<any>();
  hideTuneObs$ = this.hideTuneSubject.asObservable();

  uid!: string | null;
  user!: User;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private transferDataService: TransferDataService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })
    this.authService.user$.subscribe(user => {
      this.user = user ? user : null;
    })
  }

  checkIfUserLikedTune = async (key: string | undefined): Promise<boolean> => {
    return await this.db.object(`/liked/${this.uid}/${key}`).valueChanges().pipe(take(1)).toPromise().then(value =>
      value ? true : false
    )
  }

  likeTune = async (tune: Tune) => {
    if (this.uid) {
      let userLikedTune: boolean;

      const likeMetadata = {
        author: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP
      }

      // Check if the user already liked the tune
      userLikedTune = await this.checkIfUserLikedTune(tune.key);

      if (!userLikedTune) {

        // Push the tune to the liked tunes of the user
        delete tune.listKey; delete tune.progress; delete tune.progressSnippet; delete tune.currentTime; delete tune.image; delete tune.duration;
        const cleanTune = JSON.parse(JSON.stringify(tune));
        this.db.object(`/likedTunes/${this.uid}/${tune.key}`).set({ ...cleanTune, dateAdded: firebase.database.ServerValue.TIMESTAMP });

        // Update the amount of likes in topTunes
        this.db.object(`/topTunes/${tune.key}/likes`).query.ref.transaction(count => count ? ++count : 1);

        // Update the amount of likes in likesCount
        this.db.object(`/likesCount/${tune.key}/likesCount`).query.ref.transaction(count => count ? ++count : 1);

        // Push the data to likesTotal
        this.db.object(`/likesTotal/${tune.key}/${this.uid}`).set(likeMetadata);

        // Push the tune to the liked tune keys of the user
        this.db.object(`/liked/${this.uid}/${tune.key}`).set({
          key: tune.key,
          date: firebase.database.ServerValue.TIMESTAMP
        })

        if (!tune.blog) {
          // Add a tpoint to the uploader of the tune
          this.db.object(`/tpoints/${tune.author}/${this.uid}${tune.key}`).set(true);

          // Adds the main data of the notification
          this.db.object(`/notifications/${tune.author}/${tune.key}`).update({
            date: firebase.database.ServerValue.TIMESTAMP,
            uploadTitle: tune.uploadTitle,
            didnotsee: true,
            key: tune.key,
            liked: true,
            users: {
              [this.uid]: likeMetadata
            }
          })

          // this.db.object(`notifications/${tune.author}/${tune.key}/users/${this.uid}`).update(likeMetadata);

          // Add the currentUser to the amount of new notifications
          this.db.object(`notificationsCount/${tune.author}/${tune.key}/${this.uid}`).set(true);

          // Pushes the activityLog to each follower, except for the uploader of the tune
          this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
            followers.forEach((follower: any) => {

              // Don't push it to the log of the uploader
              if (follower.regUser !== tune.author) {

                this.db.object(`/activityLog/${follower.regUser}/${this.uid}${tune.key}like`).set({
                  date: firebase.database.ServerValue.TIMESTAMP,
                  liked: true,
                  author: this.uid,
                  otherPerson: tune.author,
                  otherUsername: tune.name,
                  otherFirstname: tune.firstname,
                  otherLastname: tune.lastname,
                  myUsername: this.user.username,
                  myFirstname: this.user.firstname,
                  myLastname: this.user.lastname,
                  myFullname: this.user.firstname + " " + this.user.lastname,
                  key: tune.key,
                  title: tune.uploadTitle,
                  uploadUrl: tune.uploadUrl
                });
              };
            });
          });
        };
      };
    } else {
      // Not logged in
      this.snackbarService.openSnackBar("You are not logged in");
    }
  }; // likeTune

  dislikeTune = async (tune: Tune) => {

    if (this.uid) {
      let userLikedTune: boolean;

      // Check if the user already liked the tune
      userLikedTune = await this.checkIfUserLikedTune(tune.key);

      if (userLikedTune) {
        // Update the amount of likes in topTunes
        this.db.object(`/topTunes/${tune.key}/likes`).query.ref.transaction(count => count ? --count : 0);

        // Update the amount of likes in likesCount
        this.db.object(`/likesCount/${tune.key}/likesCount`).query.ref.transaction(count => count ? --count : 0);

        // Removes this tune from the liked tunes of the currentUser
        this.db.object(`/likedTunes/${this.uid}/${tune.key}`).set(null);

        // Removes the notification from likesTotal
        this.db.object(`/likesTotal/${tune.key}/${this.uid}`).set(null);

        // Removes this tune from the liked tune keys of the currentUser
        this.db.object(`/liked/${this.uid}/${tune.key}`).set(null);

        if (!tune.blog) {
          // Removes a tpoint from the uploader of the tune
          this.db.object(`/tpoints/${tune.author}/${this.uid}${tune.key}`).set(null);

          // Removes the main data of the notification
          this.db.list(`/notifications/${tune.author}/${tune.key}/users`).valueChanges().pipe(take(1)).subscribe(userList => {
            // If the last user is removed, the entire notification gets deleted
            if (userList.length == 1) {
              this.db.object(`/notifications/${tune.author}/${tune.key}`).set(null);
            }
            this.db.object(`/notifications/${tune.author}/${tune.key}/users/${this.uid}`).set(null);
          })

          // Removes the currentUser from the amount of new notifications
          this.db.object(`notificationsCount/${tune.author}/${tune.key}/${this.uid}`).set(null);

          // Removes the activityLog from each follower, except for the uploader of the tune
          this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
            followers.forEach((follower: any) => {

              if (follower.regUser !== tune.author) {
                this.db.object(`/activityLog/${follower.regUser}/${this.uid}${tune.key}like`).set(null);
              };

            });
          });
        };
      };
    } else {
      // Not logged in
      this.snackbarService.openSnackBar("You are not logged in");
    }
  }; // disLike tune

  saveTune = (tune: Tune) => {
    // Delete unnessecary data
    delete tune.listKey; delete tune.progress; delete tune.progressSnippet; delete tune.currentTime; delete tune.image; delete tune.duration;

    // Add full tune to saved list
    this.db.object(`/waitlist/${this.uid}/${tune.key}`).set({ ...tune, dateUploaded: firebase.database.ServerValue.TIMESTAMP });

    // Add key to saved keys list
    this.db.object(`/addedtowaitlist/${this.uid}/${tune.key}`).set(true);
  }

  unsaveTune = (key: string) => {
    // Remove full tune from saved list
    this.db.object(`/waitlist/${this.uid}/${key}`).set(null);

    // Remove key from saved keys list
    this.db.object(`/addedtowaitlist/${this.uid}/${key}`).set(null);
  }

  searchOnSpotify(title: string) {
    const baseUrl = "https://open.spotify.com/search/";
    const titleURI = encodeURIComponent(title);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnDiscogs(title: string) {
    const baseUrl = "https://www.discogs.com/search/?q=";
    const titleURI = encodeURIComponent(title);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnBandcamp(title: string) {
    const baseUrl = "https://bandcamp.com/search?q=";
    const titleURI = encodeURIComponent(title);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  searchOnAppleMusic(title: string) {
    const baseUrl = "https://music.apple.com/us/search?term=";
    const titleURI = encodeURIComponent(title);
    const directUrl = baseUrl + titleURI;
    (window as any).open(directUrl, "_system");
  }

  shareProfile = async (username: string) => {
    const url = `https://www.tunrmusic.com/profile/${username}`;

    this.dialog.open(ShareComponent, {
        data: { url: url }
    });
  }

  repost = (tune: Tune, repostDescription?: string) => {
    this.db.list(`/uploads/${this.uid}`).push({ key: 'abc' }).then(val => {
      const uploadKey = val.key;

      // Overwrite the abc key
      this.db.object(`/uploads/${this.uid}/${uploadKey}`).set({ key: uploadKey });

      const uploadObject = {
        isRepost: true,
        originalKey: uploadKey,
        repostDescription: repostDescription ? repostDescription : null,
        repostAuthor: this.uid,
        repostDate: firebase.database.ServerValue.TIMESTAMP,
        repostUsername: this.user.username,
        repostTuneKey: tune.key
      }

      this.db.object(`/tunes/${uploadKey}`).set(uploadObject);

      // Increment the uploadsCount by 1
      this.db.object(`/uploadsCount/${this.uid}/uploadsCount`).query.ref.transaction(count => count ? ++count : 1);

      // Increment your repostedTunesCount by 1
      this.db.object(`/repostedTunesCount/${this.uid}/repostedTunesCount`).query.ref.transaction(count => count ? ++count : 1);

      // Add tune to your reposted tunes list
      this.db.object(`/repostedTunes/${this.uid}/${tune.key}`).set({ 
        key: tune.key, 
        originalKey: uploadKey,
        dateReposted: firebase.database.ServerValue.TIMESTAMP });

      // Increment the repostCount of the tune by 1
      this.db.object(`/repostCount/${tune.key}/repostCount`).query.ref.transaction(count => count ? ++count : 1);

      const repostMetadata = {
        author: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP
      }
      // Push the data to repostsTotal
      this.db.object(`/repostsTotal/${tune.key}/${this.uid}`).set(repostMetadata);

      // Add upload to your feed
      this.db.object(`/feeds/${this.uid}/${uploadKey}`).set({ key: uploadKey });

      // Add to the feed of your followers
      this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
        followers.forEach((follower: any) => {
          this.db.object(`/feeds/${follower.regUser}/${uploadKey}`).set({ key: uploadKey });
        })
      })

      // Push a notification to the author of the repost
      if (!tune.blog && this.uid !== tune.author) {
          
        this.db.object(`/notifications/${tune.author}/${tune.key}repost/users/${this.uid}`).set(repostMetadata);

        this.db.object(`/notifications/${tune.author}/${tune.key}repost`).update({
          date: firebase.database.ServerValue.TIMESTAMP,
          uploadTitle: tune.uploadTitle,
          didnotsee: true,
          key: tune.key,
          repost: true
        })

        this.db.object(`/notificationsCount/${tune.author}/${tune.key}replies`).set(true);
      }

      this.router.navigate(['/']).then(() => {
        this.transferDataService.tunePosted();
      });
    });
  }

  hideTune = (tune: Tune) => {
    this.hideTuneSubject.next(tune);
  }

  listenedToTune = (key: string | undefined) => {
    if (key) this.db.object(`/didnotlisten/${this.uid}/${key}`).set(null);
  };

  lastListenedDate() {
    if (this.uid) {
      this.db.object(`/users/${this.uid}/lastListenedDate`).set(firebase.database.ServerValue.TIMESTAMP);
      this.db.object(`/lastListenedDate/${this.uid}/lastListenedDate`).set(firebase.database.ServerValue.TIMESTAMP);
    }
  }

  reportTune = (tune: Tune, values: string[]) => {
    console.log('report ', values)
    this.db.list(`/reports/`).push({tuneKey: 'abc'}).then((val) => {
      const reportKey = val.key;
      
      const reportData = {
        tuneKey: tune.key,
        reportingUser: this.uid,
        reportedUser: tune.author,
        date: firebase.database.ServerValue.TIMESTAMP,
        type: "reportTune",
        reportId: reportKey,
        isSpam: values.includes('spam') ? true : false,
        isInappropriate: values.includes('inappropriate') ? true : false,
        isNotMusic: values.includes('notmusic') ? true : false
      }

      this.db.object(`/reports/${reportKey}`).set(reportData);

    });

    this.snackbarService.openSnackBar("Thank you for your feedback. We will look into it!");
  }
};
