import { Injectable } from '@angular/core';
import { of, Observable, from } from 'rxjs';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { DialogSignInMethodsComponent } from 'src/app/features/auth/components/dialog-sign-in-methods/dialog-sign-in-methods.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  uid!: string | null;

  uid$: Observable<string | null> = this.afAuth.authState.pipe(
    map(authState => authState ? authState.uid : null)
  )

  user$: Observable<any> = this.uid$.pipe(
    switchMap(uid => {
      if (!uid) {
        return of(null);
      } else {
        return this.db.object('/users/' + uid).valueChanges();
      }
    })
  )

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFireDatabase,
    private dialog: MatDialog
  ) {
    this.afAuth.authState.subscribe((authState: firebase.User | null) => {
      this.uid = authState?.uid ? authState.uid : null;
    })
  }

  getUid = () => {
    return this.uid;
  }

  loginWithEmail = (email: string, password: string): Observable<firebase.auth.UserCredential> => {
    return from(
      this.afAuth.signInWithEmailAndPassword(email, password)
    )
  }

  registerWithEmail = (email: string, password: string): Observable<firebase.auth.UserCredential> => {
    return from(
      this.afAuth.createUserWithEmailAndPassword(email, password)
    )
  }

  fetchSignInMethods = (email: string) => {
    this.afAuth.fetchSignInMethodsForEmail(email).then((methods: string[]) => {
      this.showSignInMethods(methods);
    }).catch((error) => {
      console.log("Error when fetching sign in methods", error);
    })
  }

  showSignInMethods = (methods: string[]) => {
    this.dialog.open(DialogSignInMethodsComponent, {
      data: { methods }
    });
  }

  continueWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    provider.addScope('public_profile');

    let userCredential!: firebase.auth.UserCredential | null;

    try {
      userCredential = await this.afAuth.signInWithPopup(provider);
    } catch (error: any) {
      console.log('Error when signin in with Facebook', error);

      if (error.code === 'auth/account-exists-with-different-credential') {
        this.fetchSignInMethods(error.email)
      }
    }

    if (!userCredential || !userCredential.user) return;

    const hasChosenUsername = await this.db.object(`/users/${userCredential.user.uid}/username`).valueChanges().pipe(take(1)).toPromise().then(hasUsername => hasUsername ? true : false);

    if (hasChosenUsername) {
      this.router.navigate(['/'])
    } else {
      const userDataIsSet = await this.db.object(`/users/${userCredential.user.uid}`).valueChanges().pipe(take(1)).toPromise().then(value => value ? true : false);
      if (!userDataIsSet) {
        this.setUserData(userCredential.user, 'facebook');
      }

      this.router.navigate(['/auth/finish-registration']);
    }
  }

  continueWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithPopup(provider);

    if (!userCredential || !userCredential.user) return;

    const hasChosenUsername = await this.db.object(`/users/${userCredential.user.uid}/username`).valueChanges().pipe(take(1)).toPromise().then(hasUsername => hasUsername ? true : false);

    if (hasChosenUsername) {
      this.router.navigate(['/'])
    } else {
      const userDataIsSet = await this.db.object(`/users/${userCredential.user.uid}`).valueChanges().pipe(take(1)).toPromise().then(value => value ? true : false);
      if (!userDataIsSet) {
        this.setUserData(userCredential.user, 'apple');
      }

      this.router.navigate(['/auth/finish-registration']);
    }
  }

  logout = () => {
    this.afAuth.signOut().then(() => {
      this.uid = null;
      this.router.navigate(['/']);
    });
  }

  setUserData = (user: any, provider: string) => {
    if (!user) return; 

    const uid: string = user.uid;
    let displayName!: string, firstname: string = '', lastname: string = '', image!: string | null;

    displayName = user.displayName;

    if (displayName) {
      firstname = displayName.split(' ')[0];
      lastname = displayName.substr(displayName.indexOf(" ") + 1);
    } else {
      console.log('No displayName')
    }

    try {
      if (provider === 'facebook') {
        try {
          image = 'https://graph.facebook.com/' + user.providerData[0]!.uid + '/picture?type=large';
        } catch (error) {
          console.log('error', error)
        }

        if (!image) image = user.photoURL;

        // Verify Facebook
        this.db.object(`/facebookVerified/${uid}`).set(true);
      } else if (provider === 'apple') {
        // Verify Apple
        this.db.object(`/appleVerified/${uid}`).set(true);
      }

      // Set image if there is still no image set
      if (!image) {
        image = "https://firebasestorage.googleapis.com/v0/b/firebase-tunr.appspot.com/o/Default-picture-grey.jpg?alt=media&token=5f4aaa53-a394-4bc4-ae67-67023ea7ab86";
      }

      const email = user.providerData[0]!.email ? user.providerData[0]!.email : "";
      const accessToken = user.stsTokenManager?.accessToken ? user.stsTokenManager.accessToken : "";
    
      // Set user in users
      this.db.object(`/users/${uid}`).set({
        date: firebase.database.ServerValue.TIMESTAMP,
        regUser: uid,
        displayName,
        firstnamePre: firstname, // TODO REMOVE
        lastnamePre: lastname, // TODO REMOVE
        fullnamePre: firstname + ' ' + lastname, // TODO REMOVE
        firstname,
        lastname,
        fullname: displayName,
        lastuploadtime: 1,
        provider,
        accessToken,
        showTutorial: true
      })

      // Set user in userSearch
      this.db.object(`/userSearch/${uid}`).set({
        date: firebase.database.ServerValue.TIMESTAMP,
        regUser: uid,
        image,
        firstname,
        lastname,
        fullname: displayName
      })

      // Set the user image
      this.db.object(`/images/${uid}`).set({ image });

      // Set the user email
      this.db.object(`/email/${uid}`).set({ email });

      // Add to followersCount, followingCount and uploadsCount 
      this.db.object(`/followersCount/${uid}`).set({ followersCount: 0 });
      this.db.object(`/followingCount/${uid}`).set({ followingCount: 0 });
      this.db.object(`/uploadsCount/${uid}`).set({ uploadsCount: 0 });

    } catch (err) {
      console.log('error', err);
    }
  }
}
