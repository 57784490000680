import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchArtists',
    pure: false
})

export class SearchArtistsPipe implements PipeTransform {
    transform(pipeData: any, pipeModifier: any): any[] {

        console.log('pipedata', pipeData)
        console.log('pipmod', pipeModifier)

        if (pipeModifier == "") {
            return pipeData;
        }

        return pipeData.filter((item: any) => {
            return (
                item['name'].toLowerCase().includes(pipeModifier.toLowerCase())
                ||
                item['number'].toLowerCase().includes(pipeModifier.toLowerCase())
                ||
                item['fullname'].toLowerCase().includes(pipeModifier.toLowerCase())
            )
        })
    }
}

