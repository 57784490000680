<!------------ INDIVIDUAL TUNE PAGE ------------>
<mat-card class="tune-indi" *ngIf="layout === 'indi'">
  <div class="tune-indi__info">
    <div *ngIf="tune" class="tune-indi__info__bg-image"
      [ngStyle]="{'background-image': 'url(https://img.youtube.com/vi/' + tune?.uploadUrl + '/default.jpg)'}"></div>
    <div class="tune-indi__info__inner">
      <div class="tune-indi__info__inner__data">
        <div class="tune-indi__info__inner__data__top">
          <div class="tune-indi__info__inner__data__top__fullsong">
            <button mat-mini-fab color="primary" aria-label="Play or pause tune" (click)="playTune('full')">
              <mat-icon
                *ngIf="!activeTune || activeTune?.type === 'snippet' || (activeTune?.type === 'full' && activeTune?.youtubeState === 2)">
                play_arrow</mat-icon>
              <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 1">pause</mat-icon>
              <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 0">refresh</mat-icon>
              <mat-progress-spinner color="secondary" mode="indeterminate" strokeWidth="2" diameter="24"
                *ngIf="activeTune?.type === 'full' && (activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5)"
                aria-label="Tune progress">
              </mat-progress-spinner>
            </button>
          </div>

          <div class="tune-indi__info__inner__data__top__title">
            {{tune?.uploadTitle}}
          </div>

          <div class="tune-indi__info__inner__data__top__thumbnail visible-l" matRipple (click)="playTune('snippet')"
            (mouseover)="showPlay()" (mouseleave)="hidePlay()"
            [ngStyle]="{'background-image': 'url(https://img.youtube.com/vi/' + tune?.uploadUrl + '/hqdefault.jpg)'}">

            <mat-progress-spinner class="tune-indi__info__inner__data__top__thumbnail__spinner hidden-s"
              *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress"
              strokeWidth="6" diameter="76" aria-label="Tune progress">
            </mat-progress-spinner>

            <mat-progress-spinner class="tune-indi__info__inner__data__top__thumbnail__spinner visible-s"
              *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress"
              strokeWidth="4" diameter="56" aria-label="Tune progress">
            </mat-progress-spinner>

            <div class="tune-indi__info__inner__data__top__thumbnail__icon-wrapper"
              *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
              <mat-icon
                *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
                play_arrow</mat-icon>
              <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
              <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
              <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
                *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
                aria-label="Tune is loading">
              </mat-progress-spinner>
            </div>

            <div *ngIf="activeTune?.type === 'snippet'" class="tune-indi__info__inner__data__top__thumbnail__overlay">
            </div>
          </div>

        </div>

        <div class="tune-indi__info__inner__data__description">
          {{tune?.reasonUpload}}
        </div>

        <div class="tune-indi__info__inner__data__bottom">
          <div class="tune-indi__info__inner__data__bottom__image" (click)="goTo('user', tune?.name, tune?.blog)">
            <img [src]="tune?.image" alt="Tune poster profile picture" />
          </div>
          <div class="tune-indi__info__inner__data__bottom__name" (click)="goTo('user', tune?.name, tune?.blog)">
            {{tune?.name}}<span *ngIf="tune?.blog" class="fas fa-crown faside icon-crown"></span>
          </div>
          <div class="tune-indi__info__inner__data__bottom__date">
            {{tune?.date | timeago:false}}
          </div>
        </div>
      </div>

      <div class="tune-indi__info__inner__thumbnail hidden-l" matRipple (click)="playTune('snippet')"
        (mouseover)="showPlay()" (mouseleave)="hidePlay()"
        [ngStyle]="{'background-image': 'url(https://img.youtube.com/vi/' + tune?.uploadUrl + '/hqdefault.jpg)'}">

        <mat-progress-spinner class="tune-indi__info__inner__thumbnail__spinner hidden-s"
          *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="6"
          diameter="76" aria-label="Tune progress">
        </mat-progress-spinner>

        <mat-progress-spinner class="tune-indi__info__inner__thumbnail__spinner visible-s"
          *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="4"
          diameter="56" aria-label="Tune progress">
        </mat-progress-spinner>

        <div class="tune-indi__info__inner__thumbnail__icon-wrapper"
          *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
          <mat-icon
            *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
            play_arrow</mat-icon>
          <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
          <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
          <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
            *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
            aria-label="Tune is loading">
          </mat-progress-spinner>
        </div>

        <div *ngIf="activeTune?.type === 'snippet'" class="tune-indi__info__inner__thumbnail__overlay"></div>
      </div>

    </div>
  </div>

  <div class="tune-indi__hashtags" *ngIf="(tune?.hashtags?.length || 0) > 0">
    <div class="tune-indi__hashtags__hashtag" *ngFor="let hashtag of tune?.hashtags"
      routerLink="/hashtag/{{hashtag?.lowercaseHashtag}}">
      {{hashtag?.originalHashtag}}
    </div>
  </div>

  <div class="tune-indi__actions">
    <div *ngIf="uid !== tune?.author">
      <!-- Like -->
      <div class="tune-indi__actions__action" *ngIf="!uid">
        <button mat-icon-button aria-label="Like tune, but user is not logged in" (click)="openAuthRequired('like')">
          <mat-icon class="tune-indi__actions__action__button__like-icon">favorite_border</mat-icon>
        </button>
      </div>
      <div class="tune-indi__actions__action" *ngIf="uid" [ngClass]="{'hide': tune?.liked}" (click)="likeTune()">
        <button mat-icon-button aria-label="Like tune">
          <mat-icon class="tune-indi__actions__action__button__like-icon">favorite_border</mat-icon>
        </button>
      </div>
      <div class="tune-indi__actions__action" *ngIf="uid" [ngClass]="{'hide': !tune?.liked}" (click)="dislikeTune()">
        <button mat-icon-button aria-label="Unlike tune">
          <mat-icon class="tune-indi__actions__action__button__liked-icon">favorite</mat-icon>
        </button>
      </div>
    </div>

    <!-- Save -->
    <div class="tune-indi__actions__action" *ngIf="!uid">
      <button mat-icon-button aria-label="Save tune, but user is not logged in" (click)="openAuthRequired('save')">
        <mat-icon class="tune-indi__actions__action__button__save-icon">bookmark_border</mat-icon>
      </button>
    </div>
    <div class="tune-indi__actions__action" *ngIf="uid" [ngClass]="{'hide': tune?.saved}" (click)="saveTune()">
      <button mat-icon-button aria-label="Save tune">
        <mat-icon class="tune-indi__actions__action__button__save-icon">bookmark_border</mat-icon>
      </button>
    </div>
    <div class="tune-indi__actions__action" *ngIf="uid" [ngClass]="{'hide': !tune?.saved}" (click)="unsaveTune()">
      <button mat-icon-button aria-label="Unsave tune">
        <mat-icon class="tune-indi__actions__action__button__saved-icon">bookmark_added</mat-icon>
      </button>
    </div>

    <!-- Share -->
    <div class="tune-indi__actions__action" (click)="openShareDialog()">
      <button mat-icon-button aria-label="Open share dialog">
        <mat-icon>share</mat-icon>
      </button>
    </div>

    <!-- Repost -->
    <div class="tune-indi__actions__action" *ngIf="!uid" (click)="openAuthRequired('repost')">
      <button mat-icon-button aria-label="Open repost tune dialog, but user not logged in">
        <mat-icon>repeat</mat-icon>
      </button>
    </div>

    <div class="tune-indi__actions__action" [ngClass]="{'hide': tune?.reposted}" *ngIf="uid && (uid !== tune?.author)"
      (click)="repost()">
      <button mat-icon-button aria-label="Open repost tune dialog" matTooltip="Repost tune" [matTooltipShowDelay]="300">
        <mat-icon>repeat</mat-icon>
      </button>
    </div>

    <div class="tune-indi__actions__action" [ngClass]="{'hide': !tune?.reposted}" *ngIf="uid && (uid !== tune?.author)"
      (click)="deleteRepost()">
      <button mat-icon-button aria-label="Open delete reposted tune dialog">
        <mat-icon class="tune-indi__actions__action__button__reposted-icon">repeat</mat-icon>
      </button>
    </div>

    <!-- Search external -->
    <div class="tune-indi__actions__action" (click)="openSearchExternalDialog()">
      <button mat-icon-button aria-label="Open search external dialog">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>

    <div style="flex: 1"></div>

    <!-- Likes total -->
    <div class="tune-indi__actions__action" *ngIf="(tune?.likesCount || 0) > 0" (click)="openLikesTotal()">
      <button mat-icon-button aria-label="Open likes from tune">
        <mat-icon class="tune-indi__actions__action--likes-total">favorite</mat-icon>
        {{tune?.likesCount}}
      </button>
    </div>

    <!-- Menu button when author of the tune -->
    <button *ngIf="tune?.author === uid" mat-icon-button class="tune-indi__actions__action"
      [matMenuTriggerFor]="optionsAuthorMenu" aria-label="Open options when author of the tune">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <!-- Menu when author of the tune -->
    <mat-menu #optionsAuthorMenu="matMenu">
      <button mat-menu-item (click)="openEditDialog()" aria-label="Open edit tune dialog">
        <mat-icon>edit</mat-icon>
        <span>Edit tune</span>
      </button>
      <button mat-menu-item (click)="openEditBuyinfoDialog()" aria-label="Open edit buy info dialog">
        <mat-icon>shopping_cart</mat-icon>
        <span>Add buy info</span>
      </button>
      <button mat-menu-item (click)="openDeleteTuneDialog()" aria-label="Open delete tune dialog">
        <mat-icon>delete</mat-icon>
        <span>Delete tune</span>
      </button>
    </mat-menu>

    <!-- Menu button when not the author of the tune -->
    <button *ngIf="tune?.author !== uid" mat-icon-button class="tune-indi__actions__action"
      [matMenuTriggerFor]="optionsNoAuthorMenu" aria-label="Open options when not the autor of the tune">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <!-- Menu when author of the tune -->
    <mat-menu #optionsNoAuthorMenu="matMenu">
      <button mat-menu-item *ngIf="!uid" (click)="openAuthRequired('report')"
        aria-label="Report tune, but user is not logged in">
        <mat-icon>flag</mat-icon>
        <span>Report</span>
      </button>
      <button mat-menu-item *ngIf="uid" (click)="reportTune()" aria-label="Report tune">
        <mat-icon>flag</mat-icon>
        <span>Report</span>
      </button>
    </mat-menu>

  </div>

  <div class="tune-indi__reply-info" *ngIf="isReply">
    <div class="tune-indi__reply-info__copy">Replying to #{{replyInfo?.username}}</div>
    <button class="tune-indi__reply-info__button" (click)="closeReply()" mat-icon-button aria-label="Close reply">
      <mat-icon inline="true">close</mat-icon>
    </button>
  </div>

  <div class="tune-indi__comment-input">
    <form [formGroup]="commentForm" (ngSubmit)="postComment(commentForm)">
      <mat-form-field appearance="fill" class="tune-indi__comment-input__field" disableUnderline>
        <mat-label *ngIf="!uid">You need to be logged in to comment</mat-label>
        <mat-label *ngIf="uid">{{commentPlaceholder}}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="message" [readonly]="!uid"
          (keydown.enter)="preventEnter($event)"
          (keyup.enter)="commentForm.valid && commentForm.dirty ? postComment(commentForm) : undefined"></textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="tune-indi__comments" *ngIf="tune?.comments && (tune?.comments?.length || 0) > 0">

    <div class="tune-indi__comments__list"
      *ngFor="let comment of tune?.comments | orderBy: 'date' | slice:0:commentsVisible">

      <app-comment [comment]="comment" [tuneKey]="tune?.key" (openReplyToComment)="openReplyToComment($event)">
      </app-comment>

      <div class="tune-indi__comments__list__replies" *ngIf="comment.repliesCount > 0">
        <div *ngFor="let reply of comment?.replies">
          <app-comment type="reply" [tuneKey]="tune?.key" [comment]="reply"
            (openReplyToComment)="openReplyToComment($event)"></app-comment>
        </div>
        <div class="comments__replies-list__toggle" *ngIf="comment?.repliesCount > (comment?.replies?.length || 0)"
          (click)="loadMoreReplies(comment)">
          Load {{comment?.repliesCount - comment?.replies?.length}} more
          <span *ngIf="(comment?.repliesCount - (comment?.replies?.length || 0)) == 1">reply</span>
          <span *ngIf="(comment?.repliesCount - (comment?.replies?.length || 0)) > 1">replies</span>
        </div>
      </div>

    </div>

    <div class="tune-indi__comments__show-more" *ngIf="(tune?.comments?.length || 0) > commentsVisible"
      (click)="showMoreComments()">
      <button mat-button class="tune__comments__show-more__button">
        <b>Load more comments</b>
      </button>
    </div>
  </div>
</mat-card>

<!------------ REGULAR SIZE ------------>

<mat-card class="tune" [ngClass]="{'tune--is-active': activeTune}" *ngIf="!layout">

  <div class="tune__repost" *ngIf="tune?.isRepost">
    <div class="tune__repost__copy">
      <div class="tune__repost__copy__info">
        <div class="tune__repost__copy__info__icon">
          <mat-icon>repeat</mat-icon>
        </div>
        <div>
          <span class="tune__repost__copy__info__repost-username" (click)="goTo('user', tune?.repostUsername)">
            {{uid === tune?.repostAuthor ? 'You' : tune?.repostUsername}}
          </span>
          reposted this tune {{tune?.date | timeago:false}}
        </div>
      </div>

      <div class="tune__repost__copy__description">
        {{tune?.repostDescription}}
      </div>
    </div>

    <!-- No author or repostAuthor and type is repost -->
    <div class="tune__repost__options" *ngIf="uid !== tune?.repostAuthor && page !== 'artist'">
      <button mat-icon-button class="tune__repost__options__button" [matMenuTriggerFor]="noAuthorOptionsMenu"
        aria-label="Show options">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #noAuthorOptionsMenu="matMenu">
        <button mat-menu-item (click)="hideTune()" aria-label="Hide tune">
          <mat-icon>visibility_off</mat-icon>
          <span>Hide tune</span>
        </button>
        <button mat-menu-item (click)="deleteFromFeed()" *ngIf="page === 'home'" aria-label="Delete tune from feed">
          <mat-icon>delete</mat-icon>
          <span>Delete from feed</span>
        </button>
        <button mat-menu-item *ngIf="!uid" (click)="openAuthRequired('report')"
          aria-label="Report tune, but user is not logged in">
          <mat-icon>flag</mat-icon>
          <span>Report</span>
        </button>
        <button mat-menu-item *ngIf="uid" (click)="reportTune()" aria-label="Report tune">
          <mat-icon>flag</mat-icon>
          <span>Report</span>
        </button>
      </mat-menu>
    </div>

    <!-- Repost author and type is repost -->
    <div class="tune__repost__options" *ngIf="uid === tune?.repostAuthor">
      <button mat-icon-button class="tune__repost__options__button" [matMenuTriggerFor]="repostAuthorMenu"
        aria-label="Show options">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #repostAuthorMenu="matMenu">
        <button mat-menu-item (click)="openEditRepostDescriptionDialog()"
          aria-label="Open edit repost description dialog">
          <mat-icon>edit</mat-icon>
          <span>Edit repost description</span>
        </button>
        <button mat-menu-item (click)="openDeleteTuneDialog()" aria-label="Open delete tune dialog">
          <mat-icon>delete</mat-icon>
          <span>Delete repost</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- Header  -->
  <div class="tune__header">
    <div class="tune__header__image" (click)="goTo('user', tune?.name, tune?.blog)">
      <img [src]="tune?.image" alt="Tune poster profile picture" />
    </div>

    <div class="tune__header__info">
      <div class="tune__header__info__username" (click)="goTo('user', tune?.name, tune?.blog)">
        {{tune?.name}}
        <span *ngIf="tune?.blog" class="fas fa-crown faside icon-crown"></span>
        <div *ngIf="tune?.didnotlisten" class="tune__header__info__username__did-not-listen" [matTooltip]="'New tune'">
        </div>
      </div>
      <div class="tune__header__info__date" *ngIf="!tune?.originalTuneDate">{{tune?.date | timeago:false}}</div>
      <div class="tune__header__info__date" *ngIf="tune?.originalTuneDate">{{tune?.originalTuneDate | timeago:false}}
      </div>
    </div>

    <div class="tune__header__actions">
      <!-- Buy now -->
      <button mat-button class="tune__header__actions__button hidden-s" *ngIf="tune?.buyComplete"
        (click)="openBuyLink(tune?.buyNowLink)" aria-label="Open link to buy tune">
        <mat-icon style="margin: 2px 4px 0 0">shopping_cart</mat-icon>
        <span>BUY </span>
        <span class="tune__header__actions__button__buy-amount" *ngIf="tune?.buyAmount">({{tune?.selectedCurrency}}
          {{tune?.buyAmount | number:'1.2-2'}})</span>
      </button>

      <!-- Buy now mobile -->
      <button mat-icon-button class="tune__header__actions__button visible-s" *ngIf="tune?.buyComplete"
        (click)="openBuyLink(tune?.buyNowLink)" aria-label="Open link to buy tune">
        <mat-icon>shopping_cart</mat-icon>
      </button>

      <!-- Save desktop -->
      <div class="hidden-s">
        <button mat-button class="tune__header__actions__button" *ngIf="!uid" aria-label="Save tune, user not logged in"
          (click)="openAuthRequired('save')">
          <mat-icon class="tune__header__actions__button__save-icon">bookmark_border</mat-icon> SAVE
        </button>
        <button mat-button class="tune__header__actions__button" *ngIf="uid" [ngClass]="{'hide': tune?.saved}"
          (click)="saveTune()" aria-label="Save tune">
          <mat-icon class="tune__header__actions__button__save-icon">bookmark_border</mat-icon> SAVE
        </button>
        <button mat-button class="tune__header__actions__button" *ngIf="uid" [ngClass]="{'hide': !tune?.saved}"
          (click)="unsaveTune()" aria-label="Unsave tune">
          <mat-icon class="tune__header__actions__button__saved-icon">bookmark_added</mat-icon> SAVED
        </button>
      </div>

      <!-- Save mobile -->
      <div class="visible-s">
        <button mat-icon-button class="tune__header__actions__button" *ngIf="!uid"
          aria-label="Save tune, user not logged in" (click)="openAuthRequired('save')">
          <mat-icon>bookmark_border</mat-icon>
        </button>
        <button mat-icon-button class="tune__header__actions__button" *ngIf="uid" [ngClass]="{'hide': tune?.saved}"
          (click)="saveTune()" aria-label="Save tune">
          <mat-icon>bookmark_border</mat-icon>
        </button>
        <button mat-icon-button class="tune__header__actions__button" *ngIf="uid" [ngClass]="{'hide': !tune?.saved}"
          (click)="unsaveTune()" aria-label="Unsave tune">
          <mat-icon class="tune__header__actions__button__saved-icon">bookmark_added</mat-icon>
        </button>
      </div>

      <!-- Menu button when author of the tune -->
      <button *ngIf="tune?.author === uid" mat-icon-button class="tune__header__actions__button"
        [matMenuTriggerFor]="optionsAuthorMenu" aria-label="Open options when author of the tune">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <!-- Menu when author of the tune -->
      <mat-menu #optionsAuthorMenu="matMenu">
        <button mat-menu-item (click)="openEditDialog()" aria-label="Open edit tune dialog">
          <mat-icon>edit</mat-icon>
          <span>Edit tune</span>
        </button>
        <button mat-menu-item (click)="openEditBuyinfoDialog()" aria-label="Open edit buy info dialog">
          <mat-icon>shopping_cart</mat-icon>
          <span>Add buy info</span>
        </button>
        <button mat-menu-item (click)="openDeleteTuneDialog()" aria-label="Open delete tune dialog">
          <mat-icon>delete</mat-icon>
          <span>Delete tune</span>
        </button>
      </mat-menu>

      <!-- Menu button when not the author of the tune -->
      <button *ngIf="tune?.author !== uid && !tune.isRepost && page !== 'artist'" mat-icon-button
        class="tune__header__actions__button" [matMenuTriggerFor]="optionsNoAuthorMenu"
        aria-label="Open options when not the autor of the tune">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <!-- Menu when author of the tune -->
      <mat-menu #optionsNoAuthorMenu="matMenu">
        <button mat-menu-item (click)="hideTune()" *ngIf="page !== 'artist'" aria-label="Hide tune">
          <mat-icon>visibility_off</mat-icon>
          <span>Hide tune</span>
        </button>
        <button mat-menu-item (click)="deleteFromFeed()" *ngIf="page === 'home'" aria-label="Delete from feed">
          <mat-icon>delete</mat-icon>
          <span>Delete from feed</span>
        </button>
        <button mat-menu-item *ngIf="!uid" (click)="openAuthRequired('report')"
          aria-label="Report tune, but user is not logged in">
          <mat-icon>flag</mat-icon>
          <span>Report</span>
        </button>
        <button mat-menu-item *ngIf="uid" (click)="reportTune()" aria-label="Report tune">
          <mat-icon>flag</mat-icon>
          <span>Report</span>
        </button>
      </mat-menu>


      <!-- No author or repostAuthor and type is repost -->
      <div class="tune__repost__options" *ngIf="uid !== tune?.author && tune.isRepost">
        <button mat-icon-button class="tune__repost__options__button" [matMenuTriggerFor]="repostAuthorNoRepostMenu"
          aria-label="Open options">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #repostAuthorNoRepostMenu="matMenu">
          <button mat-menu-item *ngIf="!uid" (click)="openAuthRequired('report')"
            aria-label="Report tune, but user is not logged in">
            <mat-icon>flag</mat-icon>
            <span>Report</span>
          </button>
          <button mat-menu-item *ngIf="uid" (click)="reportTune()" aria-label="Report tune">
            <mat-icon>flag</mat-icon>
            <span>Report</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- End of header -->

  <div class="tune__content">

    <div class="tune__content__snippet" matRipple (click)="playTune('snippet')" (mouseover)="showPlay()"
      (mouseleave)="hidePlay()">

      <mat-progress-spinner class="tune__content__snippet__spinner hidden-s"
        *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="6"
        diameter="76" aria-label="Tune progress">
      </mat-progress-spinner>

      <mat-progress-spinner class="tune__content__snippet__spinner visible-s"
        *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="4"
        diameter="56" aria-label="Tune progress">
      </mat-progress-spinner>

      <div class="tune__content__snippet__icon-wrapper" *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
        <mat-icon
          *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
          play_arrow</mat-icon>
        <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
        <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
        <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
          *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
          aria-label="Tune is loading">
        </mat-progress-spinner>
      </div>
      <img [src]="'https://img.youtube.com/vi/' + tune?.uploadUrl + '/mqdefault.jpg'" alt="Tune thumbnail" />
      <div *ngIf="activeTune?.type === 'snippet'" class="tune__content__snippet__overlay"></div>
    </div>

    <div class="tune__content__meta">
      <div class="tune__content__meta__title" (click)="goTo('tune', tune?.key)">
        {{tune?.uploadTitle}}
      </div>
      <div class="tune__content__meta__fullsong" (click)="playTune('full')">
        <button mat-mini-fab color="primary" aria-label="Play or pause tune">
          <mat-icon
            *ngIf="!activeTune || activeTune?.type === 'snippet' || (activeTune?.type === 'full' && activeTune?.youtubeState === 2)">
            play_arrow</mat-icon>
          <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 1">pause</mat-icon>
          <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 0">refresh</mat-icon>
          <mat-progress-spinner color="secondary" mode="indeterminate" strokeWidth="2" diameter="24"
            *ngIf="activeTune?.type === 'full' && (activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5)"
            aria-label="Tune progress">
          </mat-progress-spinner>
        </button>
      </div>
    </div>
  </div>

  <div class="tune__description" *ngIf="tune?.reasonUpload">
    {{tune?.reasonUpload}}
  </div>

  <div class="tune__hashtags" *ngIf="(tune?.hashtags?.length || 0) > 0">
    <div class="tune__hashtags__hashtag" *ngFor="let hashtag of tune?.hashtags"
      routerLink="/hashtag/{{hashtag?.lowercaseHashtag}}">
      {{hashtag?.originalHashtag}}
    </div>
  </div>

  <div class="tune__actions">

    <div *ngIf="uid !== tune?.author">
      <!-- Like -->
      <div class="tune__actions__action" *ngIf="!uid" (click)="openAuthRequired('like')">
        <button mat-icon-button aria-label="Like tune, but user is not logged in">
          <mat-icon class="tune__actions__action__button__like-icon">favorite_border</mat-icon>
        </button>
      </div>
      <div class="tune__actions__action" *ngIf="uid" [ngClass]="{'hide': tune?.liked}" (click)="likeTune()">
        <button mat-icon-button aria-label="Like tune">
          <mat-icon class="tune__actions__action__button__like-icon">favorite_border</mat-icon>
        </button>
      </div>
      <div class="tune__actions__action" *ngIf="uid" [ngClass]="{'hide': !tune?.liked}" (click)="dislikeTune()">
        <button mat-icon-button aria-label="Unlike tune">
          <mat-icon class="tune__actions__action__button__liked-icon">favorite</mat-icon>
        </button>
      </div>
    </div>

    <!-- Comment -->
    <div class="tune__actions__action" *ngIf="!uid">
      <button mat-icon-button (click)="openAuthRequired('comment')" aria-label="Open comment input, but not logged in">
        <i class="far fa-comment tune__actions__action__button__comment-icon"></i>
      </button>
    </div>
    <div class="tune__actions__action" *ngIf="uid">
      <button mat-icon-button (click)="toggleCommentInput()" aria-label="Open comment input">
        <i class="far fa-comment tune__actions__action__button__comment-icon"></i>
      </button>
    </div>

    <!-- Share -->
    <div class="tune__actions__action" (click)="openShareDialog()">
      <button mat-icon-button aria-label="Open share dialog">
        <mat-icon>share</mat-icon>
      </button>
    </div>

    <!-- Repost -->
    <div class="tune__actions__action" *ngIf="!uid" (click)="openAuthRequired('repost')">
      <button mat-icon-button aria-label="Open repost tune dialog, but user not logged in">
        <mat-icon>repeat</mat-icon>
      </button>
    </div>

    <div class="tune__actions__action" [ngClass]="{'hide': tune?.reposted}" *ngIf="uid && (uid !== tune?.author)"
      (click)="repost()">
      <button mat-icon-button aria-label="Open repost tune dialog" matTooltip="Repost tune" [matTooltipShowDelay]="300">
        <mat-icon>repeat</mat-icon>
      </button>
    </div>

    <div class="tune__actions__action" [ngClass]="{'hide': !tune?.reposted}" *ngIf="uid && (uid !== tune?.author)"
      (click)="deleteRepost()">
      <button mat-icon-button aria-label="Open delete reposted tune dialog">
        <mat-icon class="tune__actions__action__button__reposted-icon">repeat</mat-icon>
      </button>
    </div>

    <!-- Search external -->
    <div class="tune__actions__action" (click)="openSearchExternalDialog()">
      <button mat-icon-button aria-label="Open search external dialog">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>

    <div style="flex: 1"></div>

    <!-- Likes total -->
    <div class="tune__actions__action" *ngIf="(tune?.likesCount || 0) > 0" (click)="openLikesTotal()">
      <button mat-icon-button aria-label="Open likes from tune">
        <mat-icon class="tune__actions__action--likes-total">favorite</mat-icon>
        {{tune?.likesCount}}
      </button>
    </div>

  </div>

  <div class="tune__reply-info" *ngIf="isReply">
    <div class="tune__reply-info__copy">Replying to #{{replyInfo?.username}}</div>
    <button class="tune__reply-info__button" (click)="closeReply()" mat-icon-button aria-label="Close reply">
      <mat-icon inline="true">close</mat-icon>
    </button>
  </div>

  <div class="tune__comment-input" *ngIf="showCommentInput">
    <form [formGroup]="commentForm" (ngSubmit)="postComment(commentForm)">
      <mat-form-field appearance="fill" class="tune__comment-input__field">
        <mat-label>{{commentPlaceholder}}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="message" (keydown.enter)="preventEnter($event)"
          (keyup.enter)="commentForm.valid && commentForm.dirty ? postComment(commentForm) : undefined"></textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="tune__comments" *ngIf="tune?.comments && (tune?.comments?.length || 0) > 0">

    <div class="tune__comments__list"
      *ngFor="let comment of tune?.comments | orderBy: 'date' | slice:0:commentsVisible">

      <app-comment [comment]="comment" [tuneKey]="tune?.key" (openReplyToComment)="openReplyToComment($event)">
      </app-comment>

      <div class="tune__comments__list__replies" *ngIf="comment.repliesCount > 0">
        <div *ngFor="let reply of comment?.replies">
          <app-comment type="reply" [tuneKey]="tune?.key" [comment]="reply"
            (openReplyToComment)="openReplyToComment($event)"></app-comment>
        </div>
        <div class="comments__replies-list__toggle" *ngIf="comment?.repliesCount > (comment?.replies?.length || 0)"
          (click)="loadMoreReplies(comment)">
          Load {{comment?.repliesCount - comment?.replies?.length}} more
          <span *ngIf="(comment?.repliesCount - (comment?.replies?.length || 0)) == 1">reply</span>
          <span *ngIf="(comment?.repliesCount - (comment?.replies?.length || 0)) > 1">replies</span>
        </div>
      </div>

    </div>

    <div class="tune__comments__show-more" *ngIf="(tune?.comments?.length || 0) > commentsVisible"
      (click)="showMoreComments()">
      <button mat-button class="tune__comments__show-more__button">
        <b>Load more comments</b>
      </button>
    </div>
  </div>

</mat-card>

<!------------ SMALL SIZE ------------>

<mat-card class="tune-small" [ngClass]="{'tune--is-active': activeTune}" *ngIf="layout === 'small'">
  <div class="tune-small__rank" *ngIf="page === 'popular'">
    {{index}}
  </div>

  <div class="tune-small__snippet" matRipple (click)="playTune('snippet')" (mouseover)="showPlay()"
    (mouseleave)="hidePlay()">

    <mat-progress-spinner class="tune-small__snippet__spinner hidden-s"
      *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="6"
      diameter="76" aria-label="Tune progress">
    </mat-progress-spinner>

    <mat-progress-spinner class="tune-small__snippet__spinner visible-s"
      *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="4"
      diameter="56" aria-label="Tune progress">
    </mat-progress-spinner>

    <div class="tune-small__snippet__icon-wrapper" *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
      <mat-icon
        *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
        *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </div>
    <img [src]="'https://img.youtube.com/vi/' + tune?.uploadUrl + '/mqdefault.jpg'" alt="Tune thumbnail" />
    <div *ngIf="activeTune?.type === 'snippet'" class="tune-small__snippet__overlay"></div>
  </div>

  <div class="tune-small__meta">
    <div class="tune-small__meta__title" (click)="goTo('tune', tune?.key)">
      {{tune?.uploadTitle}}
    </div>
    <div class="tune-small__meta__subline">
      <span class="tune-small__meta__subline__username" (click)="goTo('user', tune?.name, tune?.blog)">
        {{tune?.name}}
        <span *ngIf="tune?.blog" class="fas fa-crown faside icon-crown"></span>
      </span>

      &middot;

      <span class="tune-small__meta__subline__date">
        <span *ngIf="page === 'saved'"><span class="hidden-s">saved</span> {{tune?.dateUploaded | timeago:false}}</span>
        <span *ngIf="page === 'liked'">{{tune?.dateAdded | timeago:false}}</span>
        <span *ngIf="!page || page === 'popular'">{{tune?.date | timeago:false}}</span>
      </span>
    </div>

    <div class="tune-small__meta__actions">
      <!-- Likes total -->
      <div *ngIf="((tune?.likesCount || 0) > 0) && page !== 'saved'" (click)="openLikesTotal()" class="tune-small__meta__actions__likes-total">
        <button mat-icon-button aria-label="Open likes from tune">
          <mat-icon class="tune-small__meta__actions__likes-total__icon">favorite</mat-icon>
          {{tune?.likesCount}}
        </button>
        <div class="tune-small__meta__actions__likes-total__divider">

        </div>
      </div>

      <!-- Repost -->
      <div *ngIf="!uid" (click)="openAuthRequired('repost')">
        <button mat-icon-button aria-label="Repost tune, but user is not logged in">
          <mat-icon>repeat</mat-icon>
        </button>
      </div>

      <div [ngClass]="{'hide': tune?.reposted}" *ngIf="uid && uid !== tune?.author && page !== 'saved'" (click)="repost()" matTooltip="Repost tune" [matTooltipShowDelay]="300">
        <button mat-icon-button aria-label="Open repost tune dialog">
          <mat-icon>repeat</mat-icon>
        </button>
      </div>

      <div [ngClass]="{'hide': !tune?.reposted}" *ngIf="uid && uid !== tune?.author && page !== 'saved'" (click)="deleteRepost()">
        <button mat-icon-button aria-label="Open delete reposted tune dialog">
          <mat-icon class="tune-small__meta__actions__reposted-icon">repeat</mat-icon>
        </button>
      </div>

      <div *ngIf="page === 'saved'" (click)="presentRemove()" matTooltip="Remove from saved tunes" [matTooltipShowDelay]="300">
        <button mat-icon-button aria-label="Open dialog to remove tune from saved tunes">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="tune-small__full-song" (click)="playTune('full')">
    <button mat-mini-fab color="primary" aria-label="Play or pause full tune">
      <mat-icon
        *ngIf="!activeTune || activeTune?.type === 'snippet' || (activeTune?.type === 'full' && activeTune?.youtubeState === 2)">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 1">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner color="secondary" mode="indeterminate" strokeWidth="2" diameter="24"
        *ngIf="activeTune?.type === 'full' && (activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5)"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </button>
  </div>
</mat-card>

<!------------ MINI SIZE ------------>

<mat-card class="tune-mini" [ngClass]="{'tune--is-active': activeTune}" *ngIf="layout === 'mini'">

  <div class="tune-mini__header hidden-s">
    <div class="tune-mini__header__image" (click)="goTo('user', tune?.name, tune?.blog)">
      <img [src]="tune?.image" alt="Tune poster profile picture" />
    </div>
    <div class="tune-mini__header__copy">
      <div class="tune-mini__header__copy__username" (click)="goTo('user', tune?.name, tune?.blog)">
        {{tune?.name}}
      </div>
      <div class="tune-mini__header__copy__date">
        {{tune?.date | timeago:false}}
      </div>
    </div>
  </div>

  <div class="tune-mini__snippet" matRipple (click)="playTune('snippet')" (mouseover)="showPlay()"
    (mouseleave)="hidePlay()">
    <mat-progress-spinner class="tune-mini__snippet__spinner hidden-s"
      *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="6"
      diameter="76" aria-label="Tune progress">
    </mat-progress-spinner>

    <mat-progress-spinner class="tune-mini__snippet__spinner visible-s"
      *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="4"
      diameter="56" aria-label="Tune progress">
    </mat-progress-spinner>

    <div class="tune-mini__snippet__icon-wrapper" *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
      <mat-icon
        *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
        *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </div>
    <img [src]="'https://img.youtube.com/vi/' + tune?.uploadUrl + '/mqdefault.jpg'" alt="Tune thumbnail" />
    <div *ngIf="activeTune?.type === 'snippet'" class="tune-mini__snippet__overlay"></div>
  </div>

  <div class="tune-mini__title" (click)="goTo('tune', tune?.key)">{{tune?.uploadTitle}}</div>

  <div class="tune-mini__full-song hidden-s" (click)="playTune('full')">
    <button mat-mini-fab color="primary" aria-label="Play or pause full tune">
      <mat-icon
        *ngIf="!activeTune || activeTune?.type === 'snippet' || (activeTune?.type === 'full' && activeTune?.youtubeState === 2)">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 1">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner color="secondary" mode="indeterminate" strokeWidth="2" diameter="24"
        *ngIf="activeTune?.type === 'full' && (activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5)"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </button>
  </div>
</mat-card>

<!------------ SIDEBAR SIZE ------------>

<mat-card class="tune-sidebar" [ngClass]="{'tune--is-active': activeTune}" *ngIf="layout === 'sidebar'">

  <div class="tune-sidebar__snippet" matRipple (click)="playTune('snippet')" (mouseover)="showPlay()"
    (mouseleave)="hidePlay()">

    <mat-progress-spinner class="tune-sidebar__snippet__spinner"
      *ngIf="activeTune?.type === 'snippet' && (progress > 0)" color="primary" [value]="progress" strokeWidth="4"
      diameter="56" aria-label="Tune progress">
    </mat-progress-spinner>

    <div class="tune-sidebar__snippet__icon-wrapper" *ngIf="showSnippetIcon || activeTune?.type === 'snippet'">
      <mat-icon
        *ngIf="activeTune?.youtubeState === 2 || (showSnippetIcon && activeTune?.youtubeState !== 0 && (!activeTune?.youtubeState) || activeTune?.type === 'full')">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 1 && activeTune?.type === 'snippet'">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="32"
        *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </div>
    <img [src]="'https://img.youtube.com/vi/' + tune?.uploadUrl + '/mqdefault.jpg'" alt="Tune thumbnail" />
    <div *ngIf="activeTune?.type === 'snippet'" class="tune-sidebar__snippet__overlay"></div>
  </div>

  <div class="tune-sidebar__meta">
    <div class="tune-sidebar__meta__title" (click)="goTo('tune', tune?.key)">
      {{tune?.uploadTitle}}
    </div>
    <div class="tune-sidebar__meta__subline">
      <span class="tune-sidebar__meta__subline__username" (click)="goTo('user', tune?.name, tune?.blog)">
        {{tune?.name}}
        <span *ngIf="tune?.blog" class="fas fa-crown faside icon-crown"></span>
      </span>

      &middot;

      <span class="tune-sidebar__meta__subline__date">
        <span>{{tune?.date | timeago:false}}</span>
      </span>
    </div>

  </div>

  <div class="tune-sidebar__full-song" (click)="playTune('full')">
    <button mat-mini-fab color="primary" aria-label="Play or pause full tune">
      <mat-icon
        *ngIf="!activeTune || activeTune?.type === 'snippet' || (activeTune?.type === 'full' && activeTune?.youtubeState === 2)">
        play_arrow</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 1">pause</mat-icon>
      <mat-icon *ngIf="activeTune?.type === 'full' && activeTune?.youtubeState === 0">refresh</mat-icon>
      <mat-progress-spinner color="secondary" mode="indeterminate" strokeWidth="2" diameter="24"
        *ngIf="activeTune?.type === 'full' && (activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5)"
        aria-label="Tune loading">
      </mat-progress-spinner>
    </button>
  </div>
</mat-card>
