import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransferDataService } from 'src/app/core/services/transfer-data/transfer-data.service';
import { UsersListComponent } from '../../components/users-list/users-list.component';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-likes-total',
  templateUrl: './likes-total.component.html',
  styleUrls: ['./likes-total.component.scss']
})
export class LikesTotalComponent implements OnInit {

  @ViewChild(UsersListComponent) usersListComponent!: UsersListComponent;

  tune!: Tune;
  usersListRef!: string;
  batch: number = 25;

  constructor(  
    private dialogRef: MatDialogRef<LikesTotalComponent>,
    private transferDataService: TransferDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (data.tune) this.tune = data.tune;
    this.usersListRef = `likesTotal/${this.tune.key}`;
  }

  ngOnInit(): void {
    this.transferDataService.closeDialog$.subscribe(() => {
      this.dialogRef.close();
    })
  }

  handleClickUser = () => {
    this.dialogRef.close();
  }

  refresh = (event: Event) => {
    this.usersListComponent.loadMore(event, true);
  }

  loadMore = () => {
    this.usersListComponent.loadMore();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
