import { Component, Input, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TuneInService } from 'src/app/core/services/tune-in/tune-in.service';
import { SubSink } from 'subsink';
import { AuthRequiredComponent } from '../../dialogs/auth-required/auth-required.component';

@Component({
  selector: 'app-tune-in-button',
  templateUrl: './tune-in-button.component.html',
  styleUrls: ['./tune-in-button.component.scss']
})
export class TuneInButtonComponent implements OnInit {
  private subs = new SubSink();

  @Input() username!: string | undefined;
  @Input() userUid!: string | undefined;
  @Input() size!: string | undefined;

  uid!: string | null;
  tunedIn!: boolean;
  buttonReady: boolean = false;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private tuneInService: TuneInService
  ) {
    this.uid = this.authService.getUid();

    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
      if (this.uid) this.checkIfUserIsTunedIn();
    }))
  }

  ngOnInit() {
    if (this.uid) this.checkIfUserIsTunedIn();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  checkIfUserIsTunedIn = () => {
    if (this.uid && this.username && this.userUid) {
      this.db.object(`/followingUsernames/${this.uid}/${this.username}`).valueChanges().subscribe(following => {
        this.tunedIn = following ? true : false;
        this.buttonReady = true;
      })
    }
  }

  tuneIn = () => {
    if (this.userUid) {
      this.tuneInService.tuneIn(this.userUid);
    }
  }

  tuneOut = () => {
    if (this.userUid) {
      this.tuneInService.tuneOut(this.userUid);
    }
  }

  openAuthRequired = (type: string) => {
    this.dialog.open(AuthRequiredComponent, {
      data: { type },
      width: '90%',
      maxWidth: 600
    });
  }
}
