import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../auth/auth.service';
import firebase from 'firebase/app';
import { take } from 'rxjs/operators';
import { TransferDataService } from '../transfer-data/transfer-data.service';
import { User } from 'src/app/features/shared/interfaces/user';
import { SnackbarService } from '../snackbar/snackbar.service';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { ReplyInfo } from 'src/app/features/shared/interfaces/replyInfo';
import { Comment } from 'src/app/features/shared/interfaces/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  uid!: string | null;
  user!: User | null;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private transferDataService: TransferDataService
  ) {
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })
    this.authService.user$.subscribe(user => {
      this.user = user ? user : null;
    })
  }

  postComment = async (message: string, tune: Tune, replyInfo: ReplyInfo) => {

    const userIsBlocked = await this.db.object(`/blockedUsers/${tune.author}/${this.uid}`).valueChanges().pipe(take(1)).toPromise().then(val => val ? true : false);

    if (userIsBlocked) {
      this.snackbarService.openSnackBar('Sorry, you cannot comment on the tunes of this user. He or she may have blocked you', '', 5000);
      return;
    }

    if (this.uid && this.user) {

      let commentMetadata: any = {
        author: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP,
        message
      }

      const notificationMetaData: any = {
        author: this.uid,
        username: this.user.username,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        fullname: `${this.user.firstname} ${this.user.lastname}`,
        date: firebase.database.ServerValue.TIMESTAMP
      }

      // Push the comment to the list of comments of this tune or to the list of replies
      if (!replyInfo?.parentCommentKey) {
        this.db.list(`/comments/${tune.key}`).push(commentMetadata).then(val => {
          // Send the comment to the list of comments
          commentMetadata.commentKey = val.key;
          this.transferDataService.addComment(commentMetadata);
        });

        // Update total comments count
        this.db.object(`/commentsCount/${tune.key}/commentsCount`).query.ref.transaction(count => count ? ++count : 1);

        // Push a notification to the uploader of the tune
        if (!tune.blog && this.uid !== tune.author) {
          // First delete your own user, to trigger the onCreate function after
          await this.db.object(`/notifications/${tune.author}/${tune.key}comments/users/${this.uid}`).remove();
          this.db.object(`/notifications/${tune.author}/${tune.key}comments/users/${this.uid}`).set(notificationMetaData);

          this.db.object(`/notifications/${tune.author}/${tune.key}comments`).update({
            date: firebase.database.ServerValue.TIMESTAMP,
            uploadTitle: tune.uploadTitle,
            didnotsee: true,
            key: tune.key,
            comment: true
          })

          this.db.object(`/notificationsCount/${tune.author}/${tune.key}comments`).set(true);

          // Pushes the activityLog to each follower, except for the uploader of the tune
          this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe((followers: any[]) => {
            followers.forEach((follower: any) => {
              if (this.user && follower.regUser !== tune.author) {
                this.db.object(`/activityLog/${follower.regUser}/${this.uid}${tune.key}comment`).set({
                  date: firebase.database.ServerValue.TIMESTAMP,
                  comment: true,
                  author: this.uid,
                  otherPerson: tune.author,
                  otherUsername: tune.name,
                  otherFirstname: tune.firstname !== undefined ? tune.firstname : null,
                  otherLastname: tune.lastname !== undefined ? tune.lastname : null,
                  myUsername: this.user.username,
                  myFirstname: this.user.firstname,
                  myLastname: this.user.lastname,
                  myFullname: this.user.firstname + ' ' + this.user.lastname,
                  key: tune.key,
                  title: tune.uploadTitle,
                  uploadUrl: tune.uploadUrl
                })
              }
            })
          })
        }
      } else {
        commentMetadata.parentCommentKey = replyInfo.parentCommentKey;
        commentMetadata.parentAuthor = replyInfo.author;
        commentMetadata.parentUsername = replyInfo.username;

        // Update total comments count
        this.db.object(`/commentsCount/${tune.key}/commentsCount`).query.ref.transaction(count => count ? ++count : 1);

        // Update replies count
        this.db.object(`/comments/${tune.key}/${replyInfo.parentCommentKey}/repliesCount`).query.ref.transaction(count => count ? ++count : 1);

        // Push the reply
        this.db.list(`/replies/${replyInfo.parentCommentKey}`).push(commentMetadata).then(val => {
          // Send the comment to the list of comments
          commentMetadata.replyKey = val.key;
          this.transferDataService.addComment(commentMetadata)
        });

        // Push a notification to the author of the comment
        if (!tune.blog && this.uid !== commentMetadata.parentAuthor) {
          
          // First delete your own user, to trigger the onCreate function after (because every time you respond to a comment, it has to trigger a notification)
          await this.db.object(`/notifications/${commentMetadata.parentAuthor}/${commentMetadata.parentCommentKey}replies/users/${this.uid}`).remove();
          this.db.object(`/notifications/${commentMetadata.parentAuthor}/${commentMetadata.parentCommentKey}replies/users/${this.uid}`).set(notificationMetaData);

          this.db.object(`/notifications/${commentMetadata.parentAuthor}/${commentMetadata.parentCommentKey}replies`).update({
            date: firebase.database.ServerValue.TIMESTAMP,
            uploadTitle: tune.uploadTitle,
            didnotsee: true,
            key: tune.key,
            parentCommentKey: commentMetadata.parentCommentKey,
            reply: true
          })

          this.db.object(`/notificationsCount/${commentMetadata.parentAuthor}/${commentMetadata.parentCommentKey}replies`).set(true);
        }
      }
    } else {
      // Not logged in
      this.snackbarService.openSnackBar("Please log in to post a comment");
    }
  }

  deleteComment = async (tuneKey: string, comment: Comment) => {

    if (this.uid) {
      const tune: Tune = await this.db.object(`/tunes/${tuneKey}`).valueChanges().pipe(take(1)).toPromise().then((tune: any) => tune);

      if (!comment.replyKey) {
        // Remove the comment from the list of comments of this tune
        this.db.object(`/comments/${tune.key}/${comment.commentKey}`).remove();

        await this.db.object(`/notifications/${tune.author}/${tune.key}comments/users/${this.uid}`).set(null);

        // Remove the notification from the tune uploader
        const isLastComment = await this.db.list(`/notifications/${tune.author}/${tune.key}comments/users`).valueChanges().pipe(take(1)).toPromise().then((users: any[]) => {
          if (users.length == 0) {
            return true;
          } else {
            return false;
          }
        })

        if (isLastComment) {
          this.db.object(`/notifications/${tune.author}/${tune.key}comments`).set(null);
        }

        // Removes the activityLog to each follower, except for the uploader of the tune
        this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe((followers: any[]) => {
          followers.forEach((follower: any) => {
            if (follower.regUser !== tune.author) {
              this.db.object(`/activityLog/${follower.regUser}/${this.uid}${tune.key}comment`).set(null)
            }
          })
        })

        this.transferDataService.deleteComment({
          commentKey: comment.commentKey
        });

      } else {
        // Remove the reply from the list of replies on the parent comment
        this.db.object(`/replies/${comment.parentCommentKey}/${comment.replyKey}`).remove();

        // Update replies count
        this.db.object(`/comments/${tuneKey}/${comment.parentCommentKey}/repliesCount`).query.ref.transaction(count => count ? --count : 0);

        // Remove the notification from the comment author
        await this.db.object(`/notifications/${comment.parentAuthor}/${comment.parentCommentKey}replies/users/${this.uid}`).set(null);
        
        // If it is the last reply, delete the whole object
        const isLastReply = await this.db.list(`/notifications/${comment.parentAuthor}/${comment.parentCommentKey}replies/users`).valueChanges().pipe(take(1)).toPromise().then((users: any[]) => {
          if (users.length == 0) {
            return true;
          } else {
            return false;
          }
        })

        if (isLastReply) {
          this.db.object(`/notifications/${comment.parentAuthor}/${comment.parentCommentKey}replies`).set(null);
        }

        this.transferDataService.deleteComment({
          commentKey: comment.parentCommentKey,
          replyKey: comment.replyKey
        });
      }

      // Update total comments count
      this.db.object(`/commentsCount/${tuneKey}/commentsCount`).query.ref.transaction(count => count ? --count : 0);

    } else {
      // Not logged in
      this.snackbarService.openSnackBar("You are not logged in");
    }
  }

  reportComment = (values: string[], comment: Comment, tuneKey: string) => {
    this.db.list(`/reports/`).push({tuneKey: 'abc'}).then((val) => {
      const reportKey = val.key;
      
      const reportData = {
        tuneKey: tuneKey,
        isReply: comment.replyKey ? true : false,
        comment: {...comment},
        reportingUser: this.uid,
        reportedUser: comment.author,
        date: firebase.database.ServerValue.TIMESTAMP,
        type: "reportComment",
        reportId: reportKey,
        isSpam: values.includes('spam') ? true : false,
        isInappropriate: values.includes('inappropriate') ? true : false
      }

      this.db.object(`/reports/${reportKey}`).set(reportData);
    });
  }
}
