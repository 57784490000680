import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { Activity } from 'src/app/features/shared/interfaces/activity';
import { SubSink } from 'subsink';
import _first from 'lodash/first';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TunesDataService } from 'src/app/core/services/tunes-data/tunes-data.service';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { InspirationalService } from 'src/app/core/services/inspirational/inspirational.service';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  private subs = new SubSink();

  isServer: boolean = true;

  currentRoute!: string;
  uid!: string | null;
  showSidebar: boolean = false;
  activityList = new BehaviorSubject<Activity[]>([]);
  activityEmpty: boolean = true;
  otherTunesEmpty: boolean = true;
  recentlyFollowingEmpty: boolean = true;
  recentlyFollowersEmpty: boolean = true;
  batch: number = 12;
  lastDate: number | undefined = 0;
  activityFinished: boolean = false;
  artistsFinished: boolean = false;
  notificationsLoading: boolean = true;
  otherTunesLoading: boolean = true;
  visibleSections: any[] = [];
  otherTunes: Tune[] = [];
  isIndiTuneBlog: boolean = false;
  lastNumberInspirational: number = 0;
  inspirationalBatch: number = 9;

  indiTuneKey!: string | null | undefined;
  activeArtist!: string | null | undefined;
  username!: string | null | undefined;
  followersRef!: string | null;
  followingRef!: string | null;
  hashtagsList: any[] = [];
  fullHashtagsList: any[] = [];
  hashtagsListLoading: boolean = true;
  inspirationalArtistsLoading: boolean = true;
  inspirationalArtists = new BehaviorSubject<any[]>([]);
  otherInspirationalArtistsLoading: boolean = true;
  otherInspirationalArtists: any[] = [];
  recentTunesList: Tune[] = [];
  recentTunesListLoading: boolean = true;

  showSidebar$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).pipe(
    map(result => {
      return result.matches;
    }),
    shareReplay()
  );
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private notificationsService: NotificationsService,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private tunesDataService: TunesDataService,
    private inspirationalService: InspirationalService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isServer = isPlatformServer(platformId);

    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;

      this.currentRoute = this.router.url;
      this.checkRouteChange();
    }));

    // Check if the sidebar is visible (>1280px) and only load the sidebar items if so
    this.subs.add(this.showSidebar$.subscribe(show => {
      this.showSidebar = !show;
      if (this.showSidebar) this.checkRouteChange();
    }));

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        this.checkRouteChange();
      });
  }

  ngOnInit(): void {
  }

  checkRouteChange = () => {
    if (this.isServer || !this.showSidebar || !this.currentRoute) {
      return;
    }; 

    // Root
    if (this.currentRoute === '/') {
      const refreshHashtags = this.hashtagsList.length > 0;
      this.getActiveHashtags(refreshHashtags);

      if (this.uid) {
        this.getActivity();
        this.visibleSections = ['trendingHashtags', 'activity', 'goMobile'];
      } else {
        this.getRecentTunes();
        this.visibleSections = ['trendingHashtags', 'recentTunes', 'goMobile'];
      } 
    }

    //  Discover / Liked / Saved / Hashtags
    if (this.currentRoute.startsWith('/discover') || this.currentRoute.startsWith('/liked') || this.currentRoute.startsWith('/saved') || this.currentRoute.startsWith('/hashtag')) {
      if (this.uid) this.getActivity();
      const refreshHashtags = this.hashtagsList.length > 0;
      this.getActiveHashtags(refreshHashtags);
      this.visibleSections = ['trendingHashtags', 'activity', 'goMobile'];
    }

    // Popular
    if (this.currentRoute.startsWith('/popular')) {
      const refreshHashtags = this.hashtagsList.length > 0;
      this.getActiveHashtags(refreshHashtags);
      this.getRecentTunes();
      this.visibleSections = ['trendingHashtags', 'recentTunes', 'goMobile'];
    }

    // Individual tune
    if (this.currentRoute.startsWith('/tunes')) {
      this.indiTuneKey = this.currentRoute.split('/tunes/').pop();
      if (this.indiTuneKey) this.getOtherTunes(this.indiTuneKey);
      this.visibleSections = ['otherLikedTunes', 'goMobile'];
    }

    // Profile page
    if (this.currentRoute.startsWith('/profile')) {
      this.username = this.currentRoute.split('/profile/').pop();
      if (this.username) {
        this.getOtherTunes(null, this.username);
        this.getRecentlyFollowingRef(this.username);
        this.getRecentlyFollowedByRef(this.username);
      }
      this.visibleSections = ['otherLikedTunes', 'recentlyFollowing', 'recentlyFollowedBy', 'trendingHashtags', 'goMobile'];
      const refreshHashtags = this.hashtagsList.length > 0;
      this.getActiveHashtags(refreshHashtags);
    }

    // Inspirational overview
    if (this.currentRoute == '/inspirational') {
      this.visibleSections = ['otherInspirationalArtists', 'trendingHashtags', 'goMobile'];
      const refreshHashtags = this.hashtagsList.length > 0;
      this.getActiveHashtags(refreshHashtags);
      this.getOtherInspirationalArtists();
    }

    // Inspirational artist
    if (this.currentRoute.startsWith('/inspirational/')) {
      this.activeArtist = this.currentRoute.split('/inspirational/').pop();
      if (this.activeArtist) this.getInspirationalArtists();
      this.visibleSections = ['inspirationalArtists', 'goMobile'];
    }
  }

  getActivity = (event?: any) => {
    if (this.activityFinished) return;

    this.subs.add(this.notificationsService.getActivity(this.batch, this.lastDate).pipe(
      tap((notifications: Activity[]) => {

        if (!notifications || notifications.length < 1) {
          this.activityEmpty = true;
          return;
        } else {
          this.activityEmpty = false;
        }

        // Set the lastDate in preparation for the next query
        this.lastDate = _first(notifications)?.date;

        let newNotifications = [];

        if (notifications.length < this.batch + 1) {
          newNotifications = _slice(notifications, 0, this.batch);
        } else {
          newNotifications = _slice(notifications, 1, this.batch + 1);
        }

        // Get current notifications in BehaviorSubject
        const currentNotifications = this.activityList.getValue();

        // Check which notifications are new and only push those
        const currentKeys = this.activityList.getValue().map((currentTune: any) => currentTune.originalKey);
        newNotifications = newNotifications.filter(newNotif => !currentKeys.includes(newNotif.originalKey));
        const newKeys = newNotifications.map((currentTune: any) => currentTune.originalKey);
        const combinedKeys = [...currentKeys, ...newKeys];

        // Check if there is a notification that is deleted. If so, remove from activity
        combinedKeys.forEach((key: string) => {
          this.subs.add(this.db.object(`/activityLog/${this.uid}/${key}`).valueChanges().subscribe(value => {
            if (!value) {
              this.deleteFromList(key);
            }
          }));
        });

        // Last item of the database is reached
        if (newNotifications.length > 0 && this.lastDate === _first(newNotifications)?.date) {
          this.activityFinished = true;
        }

        this.activityList.next(_concat(currentNotifications, newNotifications));

        setTimeout(() => {
          this.notificationsLoading = false;
        }, 300);

        // Close the onScroll event
        if (event) {
          event.target.complete();
        }

      })
    ).subscribe());
  }

  getOtherTunes = async (tuneKey: string | null, username?: string) => {
    this.isIndiTuneBlog = await this.db.object(`/tunes/${tuneKey}/blog`).valueChanges().pipe(take(1)).toPromise().then((isBlog: any) => isBlog);
    
    if (this.isIndiTuneBlog) {
      const artist = await this.db.object(`/tunes/${tuneKey}/name`).valueChanges().pipe(take(1)).toPromise().then((name: any) => name);
      this.subs.add(this.db.list(`/blogs/${artist}/tuneKeys`).valueChanges().pipe(take(1),
        tap(async (tunes: any[]) => {

          this.otherTunesEmpty = (tunes && tunes.length > 0) ? false : true;

          let promises: any[] = [];
          tunes.forEach((tune: any) => {
            // Get the data from each tune and push it the the array of promises
            const promise = this.tunesDataService.getTuneData(tune.key).pipe(take(1)).toPromise().then((tune: Tune) => {
              if (tune?.key !== tuneKey) {
                return tune;
              } else { return null }
            });
            promises.push(promise);
          })
          this.otherTunes = await Promise.all(promises);
          this.otherTunes = this.otherTunes.filter(tune => tune?.key);
          this.otherTunes = this.shuffle(this.otherTunes);
          this.otherTunesLoading = false;
        })).subscribe());

    } else {
      let userUid: string = '';

      if (tuneKey) {
        userUid = await this.db.object(`/tunes/${tuneKey}/author`).valueChanges().pipe(take(1)).toPromise().then((uid: any) => uid);
      } else if (username) {
        userUid = await this.db.object(`/usernames/${username}/regUser`).valueChanges().pipe(take(1)).toPromise().then((uid: any) => uid);
      }

      this.subs.add(this.db.list(`/liked/${userUid}`, ref => ref.limitToLast(4)).valueChanges().pipe(
        take(1),
        tap(async (tunes: any[]) => {

          this.otherTunesEmpty = (tunes && tunes.length > 0) ? false : true;

          let promises: any[] = [];
          tunes.forEach((tune: any) => {
            // Get the data from each tune and push it the the array of promises
            const promise = this.tunesDataService.getTuneData(tune.key).pipe(take(1)).toPromise().then(data => data);
            promises.push(promise);
          })
          this.otherTunes = await Promise.all(promises);
          this.otherTunes = this.otherTunes.filter(tune => tune.key);
          this.otherTunesLoading = false;
        })).subscribe());
    }
  }

  getRecentlyFollowingRef = async (username: string) => {
    if (username) {
      const userUid = await this.db.object(`/usernames/${username}/regUser`).valueChanges().pipe(take(1)).toPromise().then((uid: any) => uid);
      if (!userUid) return;
      
      const followingCount = await this.db.object(`/followingCount/${userUid}/followingCount`).valueChanges().pipe(take(1)).toPromise().then((count: any) => count);
      if (followingCount > 0) {
        this.followingRef = `/following/${userUid}`;
        this.recentlyFollowingEmpty = false;
      } else {
        this.recentlyFollowingEmpty = true;
      }
    }
  }

  getRecentlyFollowedByRef = async (username: string) => {
    if (username) {
      const userUid = await this.db.object(`/usernames/${username}/regUser`).valueChanges().pipe(take(1)).toPromise().then((uid: any) => uid);
      if (!userUid) return;

      const followersCount = await this.db.object(`/followersCount/${userUid}/followersCount`).valueChanges().pipe(take(1)).toPromise().then((count: any) => count);
      if (followersCount > 0) {
        this.followersRef = `/followers/${userUid}`;
        this.recentlyFollowersEmpty = false;
      } else {
        this.recentlyFollowersEmpty = true;
      }
    }
  }

  getActiveHashtags = (onRefresh?: boolean) => {
    if (onRefresh && this.hashtagsList.length > 0) {
      this.hashtagsList = this.shuffle(this.fullHashtagsList).slice(0, 5);
    } else {
      this.subs.add(this.db.list(`/hashtagsCount/`, ref => ref.orderByChild('hashtagsCount').limitToLast(200)).snapshotChanges().pipe(
        take(1),
        map((hashtags: any[]) => {
          return hashtags.map((hashtag: any) => ({ hashtagId: hashtag.payload.key, count: hashtag.payload.val().hashtagsCount }))
        }),
        tap((hashtags: any[]) => {

          this.fullHashtagsList = hashtags.filter(hashtag => hashtag.count > 0);

          hashtags.forEach((hashtag: any) => {
            this.hashtagsList.push(hashtag);
          })

          this.shuffle(this.hashtagsList);
          this.hashtagsList = this.hashtagsList.filter(hashtag => hashtag.count > 0);
          this.hashtagsList = this.hashtagsList.slice(0, 5);

        }),
        tap(() => this.hashtagsListLoading = false)
      ).subscribe());
    }
  }

  getOtherInspirationalArtists = () => {
    const randomNumber = Math.floor(Math.random() * (110));
    let stringifiedNumber: string;

    if (randomNumber < 10) {
      stringifiedNumber = String('00' + randomNumber);
    }
    else if (randomNumber < 100 && randomNumber > 9) {
      stringifiedNumber = String('0' + randomNumber);
    }
    else {
      stringifiedNumber = String(randomNumber);
    }

    this.db.list(`/blogsShort/`, ref => ref.orderByChild('number').startAt(stringifiedNumber).limitToFirst(15)).valueChanges().pipe(take(1)).subscribe((blogs: any[]) => {
      blogs.forEach((blog: any) => {
        if (blog.live) {
          this.otherInspirationalArtists.push(blog);
        }
      })

      this.shuffle(this.otherInspirationalArtists);
      this.otherInspirationalArtists = this.otherInspirationalArtists.slice(0, 5);
      this.otherInspirationalArtistsLoading = false;
    })
  }

  getInspirationalArtists = () => {
    if (this.artistsFinished) return;

    this.inspirationalService.getInspirationalArtists(this.inspirationalBatch + 1, this.lastNumberInspirational).valueChanges().pipe(
      tap((artists: any[]) => {

        // Set the lastNumber in preparation for the next query
        this.lastNumberInspirational = _first(artists)['number'];

        let newArtists = [];

        if (artists.length < this.inspirationalBatch + 1) {
          newArtists = _slice(artists, 0, this.inspirationalBatch);
        } else {
          newArtists = _slice(artists, 1, this.inspirationalBatch + 1);
        }

        // Reverse the order and only show live artists
        newArtists = newArtists.reverse().filter((artist: any) => artist.live === true && artist.name !== this.activeArtist);

        // Get current artists in BehaviorSubject
        const currentArtists = this.inspirationalArtists.getValue();

        this.inspirationalArtists.next(_concat(currentArtists, newArtists));

        // Last item of the database is reached
        if (this.lastNumberInspirational === _first(newArtists)['number']) {
          this.artistsFinished = true;
          return;
        }
      }),
      take(1))
      .subscribe();
  }

  getRecentTunes = () => {
    const recentTunesRef: AngularFireList<any> = this.db.list(`/topTunes/`, ref => ref.orderByKey().limitToLast(25));
    this.subs.add(recentTunesRef.valueChanges().pipe(
      take(1),
      tap((recentTunes: Tune[]) => {
        let sortedRecentTunes = recentTunes.sort((a: any, b: any) => b.date - a.date);

        sortedRecentTunes.forEach((recentTune: Tune) => {
          if (!recentTune.blog && this.recentTunesList.length < 10) {
            this.subs.add(this.db.object(`/images/${recentTune.author}/image`).valueChanges().pipe(take(1)).subscribe((img: any) => {
              recentTune.image = img;
            }));

            this.recentTunesList.push(recentTune);
          }
        });
      }),
      tap(() => this.recentTunesListLoading = false)
    ).subscribe());
  }

  shuffle(a: any) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  loadMoreActivity = () => {
    if (this.uid) this.getActivity();
  }

  deleteFromList = (key: string) => {
    let arr = this.activityList.getValue();
    arr.forEach((notification, index) => {
      if (notification.originalKey === key) {
        arr.splice(index, 1);
      };
    });
  }

}
