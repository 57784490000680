import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UsersDataService } from 'src/app/core/services/users-data/users-data.service';
import { SubSink } from 'subsink';
import _first from 'lodash/first';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  
  @Output('onClickUser') onClickUser: EventEmitter<any> = new EventEmitter();

  @Input() ref!: string;
  @Input() batch!: number;

  private subs = new SubSink();

  uid!: string | null;
  lastKey: string = '';
  usersList = new BehaviorSubject<any[]>([]);
  finished: boolean = false;
  usersListIsEmpty!: boolean;
  usersListLoading: boolean = true;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private usersDataService: UsersDataService
  ) {
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    }));
  }

  ngOnInit() {
    this.getUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentValue = changes.ref.currentValue;
    const previousValue = changes.ref.previousValue;

    if (currentValue && previousValue && (currentValue !== previousValue)) {
      this.usersList.next([]);
      this.lastKey = '';
      this.usersListLoading = true;
      this.getUsers();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getUsers = (event?: any, isRefresh?: boolean) => {

    if (isRefresh) this.lastKey = '';

    this.usersDataService.getUsers(`${this.ref}`, this.batch + 1, this.lastKey, 'date').pipe(
      tap(async (result:any[]) => {

        let newUsers: any[] = [];

        if (result.length === 0) {
          this.usersListIsEmpty = true;
        } else if (result.length < this.batch + 1) {
          newUsers = _slice(result, 0, this.batch);
        } else {
          newUsers = _slice(result, 1, this.batch + 1);
        }

        // Set the the lastKey for the next request
        if (result.length > 0) {
          this.lastKey = _first(result)['date'];
        }

        // Last item of the database is reached
        if (newUsers.length > 0 && (this.lastKey === _first(newUsers)['date'])) {
          this.finished = true;
        }

        if (result.length === 0) {
          this.finished = true;
        }

        // Return if the value already exists
        if (result.length === 1) {
          const currentList = this.usersList.getValue();
          if (currentList.findIndex(user => user.regUser === result[0].regUser) > -1) {
            this.finished = true;
            return;
          }
        }
        
        const currentUserUids = this.usersList.getValue().map((currentUser: any) => currentUser.regUser);

        // Only request data from users that are new
        newUsers = newUsers.filter((newUser: any) => !currentUserUids.includes(newUser));

        let imagePromises: any = [];

        newUsers.forEach((user: any) => {
          let userUid = user.regUser ? user.regUser : user.author;
          let imagePromise = this.db.object(`/images/${userUid}/image`).valueChanges().pipe(take(1)).toPromise().then(image => {
            return {
              ...user,
              image
            }
          });
          imagePromises.push(imagePromise);
        })

        newUsers = await Promise.all(imagePromises);
        
        const currentUsers = this.usersList.getValue();

        this.usersList.next(_concat(currentUsers, newUsers));

        this.usersListLoading = false;

      }), take(1))
      .subscribe();
  }

  trackByFn(index: number) {
    return index;
  }

  handleClickUser = () => {
    this.onClickUser.emit();
  }

  loadMore = (event?: Event, isRefresh?: boolean) => {
    if (isRefresh) {
      this.getUsers(event, isRefresh);
    } else {
      this.getUsers(event);
    }
  }

}
