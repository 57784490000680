import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch';
import _debounce from 'lodash/debounce';

@Component({
  selector: 'app-navigation-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchClient: SearchClient = algoliasearch('WS3E71XQ0U', '34362de1a3fb94191bfc9b3394f8e17b');
  userSearchIndex: SearchIndex = this.searchClient.initIndex('userSearch');
  hashtagsCountIndex: SearchIndex = this.searchClient.initIndex('hashtagsCount');

  private debouncedSearch = _debounce((query) => this.search(query), 250);

  searchQuery: string = "";  
  searchList: any[] = [];
  searchLoading: boolean = false;
  noSearchResults: boolean = false;
  showResults: boolean = false;
  mobileInputVisible: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  inputChange = (query: string) => {
    this.searchQuery = query;
    this.showResults = true;
    this.searchLoading = true;
    this.noSearchResults = false;
    this.debouncedSearch(this.searchQuery);
  }

  search = async (query: string) => {
    if (query.length === 0) return; 
    
    let results = [];
    let [userSearchResult, hashtagsCountResult] = await Promise.all([this.userSearchIndex.search(query), this.hashtagsCountIndex.search(query)]);

    if (hashtagsCountResult.hits.length > 0) hashtagsCountResult.hits.map((result:any) => result.isHashtag = true)

    results = [...userSearchResult.hits.slice(0, 5), ...hashtagsCountResult.hits.slice(0,5)];
    this.searchList = results;

    if (results.length === 0) this.noSearchResults = true;
    
    setTimeout(() => {
      this.searchLoading = false;
    }, 300);
  }

  clearSearchQuery = () => {
    this.searchQuery = "";
    this.showResults = false;
  }
  
  openResults = (event: any) => {
    event.stopPropagation();

    if (this.searchQuery.length > 0) {
      this.showResults = true;
    }
  }

  closeResults = () => {
    this.showResults = false;
  }

  showMobileInput = () => {
    this.mobileInputVisible = true;
  }

  hideMobileInput = () => {
    this.mobileInputVisible = false;
    this.searchQuery = "";
    this.showResults = false;
  }

  goTo = (type: string, id: string) => {
    if (type === 'user') {
      this.router.navigate([`/profile/${id}`]);
    } else if (type === 'hashtag') {
      this.router.navigate([`/hashtag/${id}`]);
    }

    this.mobileInputVisible = false;
    this.searchQuery = "";
    this.showResults = false;
  }
}
