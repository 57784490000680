import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-remove-from-saved',
  templateUrl: './remove-from-saved.component.html',
  styleUrls: ['./remove-from-saved.component.scss']
})
export class RemoveFromSavedComponent implements OnInit {
  
  uid!: string | null;
  tune!: Tune;
  
  constructor(
    private dialogRef: MatDialogRef<RemoveFromSavedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tune = {...data.tune};
  }

  ngOnInit(): void {};

  onNoClick(): void {
    this.dialogRef.close();
  }
}
