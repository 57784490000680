import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comment } from '../../interfaces/comment';

@Component({
  selector: 'app-delete-comment',
  templateUrl: './delete-comment.component.html',
  styleUrls: ['./delete-comment.component.scss']
})
export class DeleteCommentComponent implements OnInit {
  
  uid!: string | null;
  tuneKey!: string | null;
  comment!: Comment;
  
  constructor(
    private dialogRef: MatDialogRef<DeleteCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tuneKey = data.tuneKey;
    this.comment = data.comment;
  }

  ngOnInit(): void {};

  onNoClick(): void {
    this.dialogRef.close();
  }
}
