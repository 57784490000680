<div class="dialog-edit-tune">
  <h1 mat-dialog-title>Edit tune</h1>

  <div class="dialog-edit-tune__content">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <textarea matInput cdkTextareaAutosize spellcheck="false" autocomplete="off" type="text"
      #biographyInput [(ngModel)]="tune.uploadTitle" maxlength="350"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize spellcheck="false" autocomplete="off" type="text" maxlength="1000"
        [(ngModel)]="tune.reasonUpload"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Hashtags</mat-label>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let hashtag of hashtags" [selectable]="true" [removable]="true"
          (removed)="removeHashtag(hashtag)">
          {{hashtag?.originalHashtag}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Press enter or comma to add a hashtag" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addHashtag($event)" [disabled]="hashtagsDisabled">
      </mat-chip-list>
      <mat-hint align="end">{{hashtags.length || 0}}/10</mat-hint>
    </mat-form-field>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial [disabled]="loading"
      class="dialog-edit-tune__save-button">
      <span *ngIf="!loading">Save</span>
      <mat-spinner class="dialog-edit-tune__save-button__spinner" *ngIf="loading" diameter="25"></mat-spinner>
    </button>
  </div>

</div>
