import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthReversedGuard } from './core/guards/auth-reversed.guard';
import { IsAdminGuard } from './core/guards/is-admin.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/root/root.module').then(m => m.RootModule) 
  },
  {
    path: 'discover',
    loadChildren: () => import('./features/discover/discover.module').then(m => m.DiscoverModule) 
  },
  {
    path: 'inspirational',
    loadChildren: () => import('./features/inspirational/inspirational.module').then(m => m.InspirationalModule) 
  },
  {
    path: 'inspirational/:artist',
    loadChildren: () => import('./features/inspirational/artist/artist.module').then(m => m.ArtistModule) 
  },
  {
    path: 'liked-tunes',
    loadChildren: () => import('./features/liked-tunes/liked-tunes.module').then(m => m.LikedTunesModule),
    //canActivate: [AuthReversedGuard] 
  },
  {
    path: 'popular',
    loadChildren: () => import('./features/popular/popular.module').then(m => m.PopularModule) 
  },
  {
    path: 'about',
    loadChildren: () => import('./features/about/about.module').then(m => m.AboutModule) 
  },
  {
    path: 'links',
    loadChildren: () => import('./features/links/links.module').then(m => m.LinksModule) 
  },
  {
    path: 'termsAndConditions',
    loadChildren: () => import('./features/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) 
  },
  {
    path: 'privacyPolicy',
    loadChildren: () => import('./features/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) 
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule) 
  },
  {
    path: 'hashtag/:id',
    loadChildren: () => import('./features/hashtags/hashtags.module').then(m => m.HashtagsModule) 
  },
  {
    path: 'tunes/:id',
    loadChildren: () => import('./features/indi-tune/indi-tune.module').then(m => m.IndiTuneModule) 
  },
  {
    path: 'saved',
    loadChildren: () => import('./features/saved/saved.module').then(m => m.SavedModule),
    //canActivate: [AuthReversedGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'post',
    loadChildren: () => import('./features/post/post.module').then(m => m.PostModule),
    canActivate: [AuthReversedGuard] 
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'notificationsFollowers',
    loadChildren: () => import('./features/notifications-followers/notifications-followers.module').then(m => m.NotificationsFollowersPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthReversedGuard]
  },
  {
    path: 'auth',
    data: { showSidebar: false },
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) 
  },
  {
    path: '**',
    redirectTo: '/'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
